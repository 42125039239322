import { generateStateContext } from 'src/utils/context';

interface State {
  showSearchModal: boolean;
  showAlbumSelectModal: boolean;
  showDateUpdateModal: boolean;
  selectMode?: 'delete' | 'album' | 'download' | 'update-date';
}

const initialState: State = {
  showSearchModal: false,
  showDateUpdateModal: false,
  showAlbumSelectModal: false,
};

export const [PhotoListContext, PhotoListProvider] = generateStateContext(initialState);
