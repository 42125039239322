import { generateInfiniteQuery } from '../generate-infinite-query';
import { GeneticCreature } from 'src/model/genetic';

export interface GeneticCreatureSearchParams {
  query?: string;
}

const geneticCreatureSearchApi = generateInfiniteQuery<GeneticCreatureSearchParams, GeneticCreature>({
  api: (params) => ({
    url: `/api/v1/search/creatures`,
    method: 'POST',
    data: params,
  }),
  key: 'geneticCreatureSearchApi',
});

export default geneticCreatureSearchApi;
