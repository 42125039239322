import { t } from 'i18next';

import GeneticRoutes from '../GeneticRoutes';
import GeneticCreatureList from './components/GeneticCreatureList';
import { useFlattenPageData, useTotal } from 'src/api/generate-infinite-query';
import geneticCreatureSearchApi from 'src/api/genetic/genetic-creature-search';
import { Loading2 } from 'src/components/common/LoadingSuspense';
import CommonLayout from 'src/components/common/layout/CommonLayout';
import { useScrollBottom } from 'src/hooks/scroll-bottom';
import { HeaderButton } from 'src/model/component';
import router from 'src/pages/router';

export default function GeneticCreatureListPage() {
  const { data, isFetching, fetchNextPage } = geneticCreatureSearchApi.useInfiniteApi({}, { suspense: false });
  const creatures = useFlattenPageData(data);
  const total = useTotal(data);

  useScrollBottom(() => {
    if (!isFetching) {
      fetchNextPage();
    }
  });

  const headerBtns: HeaderButton[] = [
    {
      icon: 'fas fa-plus',
      name: t('add'),
      onClick: () => router.navigate(GeneticRoutes.createRoute),
    },
  ];

  return (
    <CommonLayout className="GeneticCreatureListPage" title={t('GeneticNavigation.list')} btns={headerBtns}>
      <Loading2 isLoading={isFetching}>
        <h3>{t('CommonSearchResult.result-num', { total })}</h3>
        {data && <GeneticCreatureList creatures={creatures} />}
      </Loading2>
    </CommonLayout>
  );
}
