import CustomDropdown from './CustomDropdown';
import { ChronoUnit, ChronoUnits } from 'src/model/api';

interface Props {
  onChange: (value: ChronoUnit) => void;
  value?: ChronoUnit;
}

export default function ChronoUnitSelect(props: Props) {
  const { onChange, value } = props;

  return <CustomDropdown data={ChronoUnits} labelSelector={(v) => v} onChange={onChange} value={value} />;
}
