import GeneticRoutes from '../GeneticRoutes';
import geneticCreatureCreateApi from 'src/api/genetic/genetic-creature-create';
import { GeneticCreatureFormState } from 'src/components/genetic/form/GeneticCreatureForm';
import router from 'src/pages/router';
import { dispatch } from 'src/redux';
import { GlobalActions } from 'src/redux/global';

export interface GeneticCreatureCreatePageParams {}

function usePageParams(): GeneticCreatureCreatePageParams {
  return {};
}

function useCreate() {
  return async (state: GeneticCreatureFormState) => {
    dispatch(GlobalActions.update({ loading: true }));

    const result = await geneticCreatureCreateApi(state);
    const creatureId = result.id;

    dispatch(GlobalActions.update({ loading: false }));

    router.navigate(GeneticRoutes.detail({ creatureId }), { replace: true });
  };
}

const GeneticCreatureCreateHooks = {
  usePageParams,
  useCreate,
};

export default GeneticCreatureCreateHooks;
