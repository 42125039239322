import { t } from 'i18next';
import { Button, Form } from 'react-bootstrap';
import { useController, useForm } from 'react-hook-form';

import GeneticTypeRadioButton from './GeneticTypeRadioButton';
import GenderRadioButton from 'src/components/common/form/GenderRadioButton';
import { GeneticCreature } from 'src/model/genetic';
import { toDateString } from 'src/utils/date';

interface Props {
  creature?: GeneticCreature;
  onSubmit: (state: GeneticCreatureFormState) => void;
}

export interface GeneticCreatureFormState {
  name: string;
  birthday: string;
  punch: string;
  isMale: boolean;
  type1: number;
  type2: number;
  type3: number;
  type4: number;
}

export default function GeneticCreatureForm(props: Props) {
  const defaultValues = useDefaultValues(props.creature);
  const { register, handleSubmit, control, formState } = useForm<GeneticCreatureFormState>({ defaultValues });
  const genderField = useController({ name: 'isMale', control }).field;
  const type1Field = useController({ name: 'type1', control }).field;
  const type2Field = useController({ name: 'type2', control }).field;
  const type3Field = useController({ name: 'type3', control }).field;
  const type4Field = useController({ name: 'type4', control }).field;
  const { errors } = formState;

  return (
    <Form className="GeneticCreatureForm d-grid gap-3" onSubmit={handleSubmit(props.onSubmit)}>
      <Form.Group>
        <Form.Label>{t('GeneticCreature.name')}</Form.Label>
        <Form.Control
          {...register('name', { required: t('common.form.feedback.required') })}
          isInvalid={!!errors.name}
        />
        <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('GeneticCreature.birthday')}</Form.Label>
        <Form.Control
          {...register('birthday', { required: t('common.form.feedback.required') })}
          isInvalid={!!errors.birthday}
        />
        <Form.Control.Feedback type="invalid">{errors.birthday?.message}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('GeneticCreature.punch')}</Form.Label>
        <Form.Control
          {...register('punch', { required: t('common.form.feedback.required') })}
          isInvalid={!!errors.punch}
        />
        <Form.Control.Feedback type="invalid">{errors.punch?.message}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('GeneticCreature.isMale')}</Form.Label>
        <GenderRadioButton onChange={genderField.onChange} value={genderField.value} />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('genetic.type1')}</Form.Label>
        <GeneticTypeRadioButton onChange={type1Field.onChange} value={type1Field.value} />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('genetic.type2')}</Form.Label>
        <GeneticTypeRadioButton onChange={type2Field.onChange} value={type2Field.value} />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('genetic.type3')}</Form.Label>
        <GeneticTypeRadioButton onChange={type3Field.onChange} value={type3Field.value} />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('genetic.type4')}</Form.Label>
        <GeneticTypeRadioButton onChange={type4Field.onChange} value={type4Field.value} />
      </Form.Group>
      <Button variant="primary" type="submit">
        {t('complete')}
      </Button>
    </Form>
  );
}

function useDefaultValues(creature?: GeneticCreature): GeneticCreatureFormState {
  if (!creature) {
    return {
      name: '',
      birthday: toDateString(new Date()),
      punch: '',
      isMale: true,
      type1: 0,
      type2: 0,
      type3: 0,
      type4: 0,
    };
  }

  return {
    name: creature.name,
    birthday: creature.birthday,
    punch: creature.punch,
    isMale: creature.isMale,
    type1: creature.type1,
    type2: creature.type2,
    type3: creature.type3,
    type4: creature.type4,
  };
}
