import { DiaryListProvider } from './DiaryListContext';
import DiaryListHooks from './DiaryListHooks';
import DiarySearchModal from './components/DiarySearchModal';
import diarySearchApi from 'src/api/diary/diary-search';
import { useFlattenPageData } from 'src/api/generate-infinite-query';
import CommonLayout from 'src/components/common/layout/CommonLayout';
import SearchResultWrapper from 'src/components/common/search/SearchResultWrapper';
import DiaryPreviewList from 'src/components/diary/DiaryPreviewList';

function DiaryListPage() {
  const { query } = DiaryListHooks.usePageParams();
  const searchParams = DiaryListHooks.useSearchParams();

  const result = diarySearchApi.useInfiniteApi(searchParams, { suspense: false });
  const diaries = useFlattenPageData(result.data);

  const headerProps = DiaryListHooks.useHeaderProps();

  return (
    <CommonLayout className="DiaryListPage" {...headerProps}>
      <SearchResultWrapper query={query} result={result}>
        <DiaryPreviewList diaries={diaries} query={query} />
      </SearchResultWrapper>
      <DiarySearchModal />
    </CommonLayout>
  );
}

export default function DiaryListIndex() {
  return (
    <DiaryListProvider>
      <DiaryListPage />
    </DiaryListProvider>
  );
}
