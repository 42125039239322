import geneticCreatureDeleteApi from 'src/api/genetic/genetic-creature-delete';
import { useUrlParams } from 'src/hooks/url-params';
import { GeneticCreature } from 'src/model/genetic';
import router from 'src/pages/router';
import { dispatch } from 'src/redux';
import { GlobalActions } from 'src/redux/global';

export interface GeneticCreatureDetailPageParams {
  creatureId: string;
}

function usePageParams(): GeneticCreatureDetailPageParams {
  const [creatureId] = useUrlParams('creatureId');
  return { creatureId };
}

function useDelete() {
  return async (creature: GeneticCreature) => {
    dispatch(GlobalActions.update({ loading: true }));

    await geneticCreatureDeleteApi({ creatureId: creature.id });

    dispatch(GlobalActions.update({ loading: false }));

    router.navigate(-1);
  };
}

const GeneticCreatureDetailHooks = {
  usePageParams,
  useDelete,
};

export default GeneticCreatureDetailHooks;
