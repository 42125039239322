import { t } from 'i18next';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import FriendMultiSelect from '../friend/FriendMultiSelect';
import { Diary } from 'src/model/diary';
import { toDateString } from 'src/utils/date';

interface Props {
  diary?: Diary;
  onSubmit: (diary: Diary) => void;
  initialDate?: string;
}

export default function DiaryForm({ diary, onSubmit, initialDate }: Props) {
  const defaultValues: Diary = diary ?? {
    date: initialDate ?? toDateString(new Date()),
    summary: '',
    content: '',
    friends: [],
    photoNum: 0,
    photos: [],
  };

  const { register, handleSubmit, watch, setValue } = useForm<Diary>({ defaultValues });

  const content = watch('content');

  return (
    <Form className="DiaryForm d-grid gap-3" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="diary_form_title">
        <Form.Label>{t('DiaryForm.date')}</Form.Label>
        <Form.Control size="lg" type="text" placeholder="yyyy-MM-dd" {...register('date')} />
      </Form.Group>
      <hr className="m-0" />
      <Form.Group controlId="diary_form_summary">
        <Form.Label>{t('DiaryForm.summary')}</Form.Label>
        <Form.Control type="text" placeholder="Summary" {...register('summary')} />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('DiaryForm.friends')}</Form.Label>
        <FriendMultiSelect value={watch('friends')} onChange={(v) => setValue('friends', v)} />
      </Form.Group>
      <Form.Group controlId="diary_form_content">
        <Form.Label>{t('DiaryForm.content')}</Form.Label>
        <Form.Control as="textarea" rows={10} {...register('content')} />
        <div className="text-end">{t('letters', { cnt: content.length })}</div>
      </Form.Group>
      <Button variant="primary" type="submit">
        {t('complete')}
      </Button>
    </Form>
  );
}
