import { decode } from 'js-base64';
import { useCallback } from 'react';

import { PhotoSearchParams } from 'src/api/photo/photo-search';
import { useOptionalUrlParams } from 'src/hooks/url-params';
import { PhotoPreview } from 'src/model/photo';

export interface PhotoViewerPageParams {
  search?: string;
  photoId?: string;
}

function usePageParams(): PhotoViewerPageParams {
  const [search, photoId] = useOptionalUrlParams('search', 'photoId');
  return { search, photoId };
}

function useSearchParams(): PhotoSearchParams {
  const { search, photoId } = usePageParams();
  const tmp = search ? JSON.parse(decode(search)) : {};
  return {
    ...tmp,
    photoId,
  };
}

function useOnIndexReady(slides: (PhotoPreview | null)[], fetchPage: (page: number) => void, pageSize?: number) {
  return useCallback(
    (from: number, to: number) => {
      if (!pageSize) {
        return;
      }

      if (!slides[from]) {
        const fromPage = Math.floor(from / pageSize);
        fetchPage(fromPage);
      }
      if (!slides[to]) {
        const toPage = Math.floor(to / pageSize);
        fetchPage(toPage);
      }
    },
    [slides, pageSize, fetchPage],
  );
}

const PhotoViewerHooks = {
  usePageParams,
  useSearchParams,
  useOnIndexReady,
};

export default PhotoViewerHooks;
