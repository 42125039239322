import cs from 'classnames';

import { FriendPreview } from 'src/model/friend';

import './DiaryMeetFriendView.scss';

interface Props {
  className?: string;
  small?: boolean;
  meetFriends: FriendPreview[];
}

export default function DiaryMeetFriendView({ small, meetFriends, className }: Props) {
  if (meetFriends.length === 0) {
    return <></>;
  }

  const friends = meetFriends.map((v) => v.name).join(', ');

  const className2 = small ? 'gap-1' : 'gap-2';

  return <div className={cs('DiaryMeetFriendView', className, className2)}>{friends}</div>;
}
