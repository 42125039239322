import { generateQuery } from 'src/api/generate-api';
import { GeneticCreature } from 'src/model/genetic';

export interface GeneticCreatureDetailParams {
  creatureId: string;
}

const geneticCreatureDetailApi = generateQuery<GeneticCreatureDetailParams, GeneticCreature | null>({
  api: ({ creatureId }) => ({
    url: `/api/v1/creatures/${creatureId}`,
    method: 'GET',
  }),
  key: 'geneticCreatureDetailApi',
});

export default geneticCreatureDetailApi;
