import queryString from 'query-string';

import { LoginPageParams } from './login/LoginHooks';
import { RegisterPageParams } from './register/RegisterHooks';

const { stringifyUrl } = queryString;

const AuthRoutes = {
  loginRoute: '/login',
  login: ({ ...query }: LoginPageParams) => stringifyUrl({ url: `/login`, query }),

  registerRoute: '/register',
  register: ({ ...query }: RegisterPageParams) => stringifyUrl({ url: `/register`, query }),

  profile: '/profile',
  admin: '/admin',
};

export default AuthRoutes;
