import { t } from 'i18next';

import PhotoDetailHooks from '../PhotoDetailHooks';
import { PhotoPreview } from 'src/model/photo';
import { toLocaleString } from 'src/utils/date';

import './PhotoDetailView.scss';

interface Props {
  photo: PhotoPreview;
}

export default function PhotoDetailView({ photo }: Props) {
  const showDateModal = PhotoDetailHooks.useShowDateModal(true);

  return (
    <div className="PhotoDetailView">
      <div className="file_name">{photo.fileName}</div>
      {renderContent(photo)}
      <div className="info">
        <div className="info_item">
          <div>{t('PhotoInfoSection.image-size')}</div>
          <div>{photo.imageSize}</div>
        </div>
        <div className="info_item">
          <div>{t('PhotoInfoSection.file-size')}</div>
          <div>{photo.fileSize}</div>
        </div>
        <div className="info_item">
          <div>{t('PhotoInfoSection.reg-dt')}</div>
          <div>{toLocaleString(photo.regDt)}</div>
        </div>
        <div className="info_item cursor_pointer" onClick={showDateModal}>
          <div>
            <span>{t('PhotoInfoSection.date')}</span> <i className="fas fa-pen" />
          </div>
          <div>{toLocaleString(photo.date)}</div>
        </div>
      </div>
    </div>
  );
}

function renderContent(photo: PhotoPreview): JSX.Element {
  if (photo.type === 'VIDEO') {
    return (
      <video controls className="img-fluid">
        <source src={photo.original} type="video/mp4" />
      </video>
    );
  }

  return <img className="img-fluid" src={photo.original} alt={photo.fileName} />;
}
