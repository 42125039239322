import { t } from 'i18next';

import GeneticCreatureCreateHooks from './GeneticCreatureCreateHooks';
import CommonLayout from 'src/components/common/layout/CommonLayout';
import GeneticCreatureForm from 'src/components/genetic/form/GeneticCreatureForm';

export default function GeneticCreatureCreatePage() {
  const create = GeneticCreatureCreateHooks.useCreate();

  return (
    <CommonLayout className="GeneticCreatureCreatePage" title={t('CeneticCreatureCreatePage.title')} back>
      <GeneticCreatureForm onSubmit={create} />
    </CommonLayout>
  );
}
