export interface ErrorResponse {
  code: string;
  msg: string;
  payload: any;
}

export interface PageData<T> {
  total: number;
  page: number;
  pageSize: number;
  data: T[];
}

export interface MergedPageData<T> {
  total: number;
  pageSize: number;
  data: (T | null)[];
}

export interface SearchStatus {
  total: number;
  nowPage: number;
  totalPage: number;
}

export interface SearchResult<T> {
  data?: T[];
  status?: SearchStatus;
}

export interface SimpleResponse {
  result: string;
}

export interface DateRange {
  start: string;
  end: string;
}

const _ChronoUnits = {
  SECONDS: 'SECONDS',
  MINUTES: 'MINUTES',
  HOURS: 'HOURS',
  DAYS: 'DAYS',
  WEEKS: 'WEEKS',
  MONTHS: 'MONTHS',
  YEARS: 'YEARS',
} as const;

export const ChronoUnits = Object.keys(_ChronoUnits) as ChronoUnit[];

export type ChronoUnit = (typeof _ChronoUnits)[keyof typeof _ChronoUnits];
