import geneticCreatureUpdateApi from 'src/api/genetic/genetic-creature-update';
import { GeneticCreatureFormState } from 'src/components/genetic/form/GeneticCreatureForm';
import { useUrlParams } from 'src/hooks/url-params';
import router from 'src/pages/router';
import { dispatch } from 'src/redux';
import { GlobalActions } from 'src/redux/global';

export interface GeneticCreatureUpdatePageParams {
  creatureId: string;
}

function usePageParams(): GeneticCreatureUpdatePageParams {
  const [creatureId] = useUrlParams('creatureId');
  return { creatureId };
}

function useUpdate() {
  const { creatureId } = usePageParams();

  return async (state: GeneticCreatureFormState) => {
    dispatch(GlobalActions.update({ loading: true }));

    const params = { creatureId, ...state };
    await geneticCreatureUpdateApi(params);

    dispatch(GlobalActions.update({ loading: false }));

    router.navigate(-1);
  };
}

const GeneticCreatureUpdateHooks = {
  usePageParams,
  useUpdate,
};

export default GeneticCreatureUpdateHooks;
