import { generateApi } from '../generate-api';
import albumDetailApi from './album-detail';
import albumListApi from './album-list';
import { Album } from 'src/model/photo';

export interface AlbumUpdateParams {
  albumId: string;
  thumbnailId?: string;
  name?: string;
}

const albumUpdateApi = generateApi<AlbumUpdateParams, Album>({
  api: (params) => ({
    url: `/api/v1/albums/${params.albumId}`,
    method: 'PUT',
    data: params,
  }),
  postHandle: (result, params) => {
    const { albumId } = params;
    albumListApi.invalidate();
    albumDetailApi.setCache({ albumId }, result);
  },
});

export default albumUpdateApi;
