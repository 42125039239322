import friendListApi from 'src/api/friend/friend-list';
import CustomMultiSelect from 'src/components/common/select/CustomMultiSelect';
import { FriendPreview } from 'src/model/friend';

interface Props {
  enabled?: boolean;
  value: FriendPreview[];
  onChange: (value: FriendPreview[]) => void;
  userOnly?: boolean;
}

export default function FriendMultiSelect(props: Props) {
  const { enabled, userOnly } = props;
  const { data, isLoading } = friendListApi.useApiResult({ userOnly }, { suspense: false, enabled });
  const friends = data ?? [];
  const value = props.value.map((v) => friends.filter((it) => it.id === v.id)[0]).filter((v) => !!v);

  return (
    <CustomMultiSelect
      data={friends}
      labelSelector={(v) => v.name}
      onChange={props.onChange}
      value={value}
      isLoading={isLoading}
    />
  );
}
