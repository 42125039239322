import { generateQuery } from '../generate-api';
import { GeneticCombination } from 'src/model/genetic';

export interface GeneticCombinationResult {
  result: GeneticCombination[];
}

const geneticCombinationApi = generateQuery<{}, GeneticCombinationResult>({
  api: (params) => ({
    url: `/api/v1/combination`,
    method: 'POST',
    data: params,
  }),
  key: 'geneticCombinationApi',
});

export default geneticCombinationApi;
