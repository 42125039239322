import { t } from 'i18next';
import { Button, ProgressBar } from 'react-bootstrap';

import PhotoDownloadHooks from '../PhotoDownloadHooks';
import { timeAgo } from 'src/i18n';
import { PhotoDownload } from 'src/model/photo';
import { useBreakpointMobile } from 'src/utils/breakpoint';

import './PhotoDownloadItem.scss';

interface Props {
  index: number;
  download: PhotoDownload;
}

export default function PhotoDownloadItem(props: Props) {
  const { index, download } = props;
  const { content, total } = download;
  const isMobile = useBreakpointMobile();

  const downloadButton = useDownloadButton(download);

  if (isMobile) {
    return (
      <div className="PhotoDownloadItem gray_bg_hover">
        <div className="content">{t('PhotoDownloadList.content-data', { content, total })}</div>
        <div className="d-flex align-items-center justify-content-between mt-1">
          <div className="status">{renderProgress(download)}</div>
          <div className="date">{timeAgo.format(new Date(download.date))}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="PhotoDownloadItem gray_bg_hover">
      <div className="index">{index}</div>
      <div className="content">{t('PhotoDownloadList.content-data', { content, total })}</div>
      <div className="status">{renderProgress(download)}</div>
      <div className="date">{timeAgo.format(new Date(download.date))}</div>
      <div className="download">{downloadButton}</div>
    </div>
  );
}

function renderProgress(download: PhotoDownload) {
  const { progress, total } = download;
  const label = `${progress} / ${total}`;

  if (progress === total) {
    return <ProgressBar now={progress} max={total} label={label} variant="success" />;
  }

  return <ProgressBar now={progress} max={total} label={label} animated />;
}

function useDownloadButton(download: PhotoDownload) {
  const { progress, total, url } = download;
  const downloadZip = PhotoDownloadHooks.useDownload();

  const onClick = () => {
    downloadZip(url);
  };

  if (progress === total) {
    return (
      <Button size="sm" variant="primary" onClick={onClick}>
        {t('download')}
      </Button>
    );
  }

  return (
    <Button size="sm" variant="outline-secondary" disabled>
      {t('download')}
    </Button>
  );
}
