import { floor } from 'lodash';
import { FieldPath, FieldPathValue, FieldValues, UseControllerProps } from 'node_modules/react-hook-form/dist/types';
import { useController } from 'react-hook-form';

export function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function isDev() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}

export function urlToName(url: string) {
  return decodeURIComponent(url.split('/').reverse()[0]);
}

export function numberWithComma(num: number | undefined) {
  if (!num) {
    return '';
  }

  const [part1, part2] = num.toString().split('.');

  const withComma = part1.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (part2) {
    return `${withComma}.${part2}`;
  } else {
    return withComma;
  }
}

export function filterEmptyString(obj: any): any {
  const result: any = {};

  Object.entries(obj).forEach(([k, v]) => {
    if (v !== '') {
      result[k] = v;
    }
  });

  return result;
}

export function getHumanReadableSize(size: number) {
  const kb = 1000;
  const mb = 1000 * 1000;
  const gb = 1000 * 1000 * 1000;

  if (size > gb) {
    const tmp = floor(size / gb, 2);
    return `${tmp} GB`;
  }

  if (size > mb) {
    const tmp = floor(size / mb, 2);
    return `${tmp} MB`;
  }

  const tmp = floor(size / kb, 2);
  return `${tmp} KB`;
}

const BASE = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

export function generateRandomString(length: number) {
  return Array.from({ length }, () => BASE.charAt(Math.floor(Math.random() * BASE.length))).join('');
}

export function useControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: UseControllerProps<TFieldValues, TName>,
): {
  value: FieldPathValue<TFieldValues, TName>;
  onChange: (...event: any[]) => void;
} {
  const { field } = useController(props);
  return { value: field.value, onChange: field.onChange };
}

export function downloadFile(url: string, name: string) {
  const link = document.createElement('a');
  link.href = `${url}?downloadName=${encodeURI(name)}`;
  link.download = name;
  link.click();
}
