import { RefObject, useEffect } from 'react';

export function useScrollBottom(callback: () => void) {
  useEffect(() => {
    const handler = () => {
      const totalHeight = document.documentElement.scrollHeight;
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const remaining = totalHeight - scrollY - windowHeight;

      if (remaining < 100) {
        callback();
      }
    };

    document.addEventListener('scroll', handler);

    return () => {
      document.removeEventListener('scroll', handler);
    };
  }, [callback]);
}

export function useElementScrollTop(ref: RefObject<HTMLElement>, callback: (isTop: boolean) => void) {
  useEffect(() => {
    const handler = () => {
      const rect = ref.current?.getBoundingClientRect();
      if (!rect) {
        return;
      }

      callback(rect.top <= 0);
    };

    document.addEventListener('scroll', handler);

    return () => {
      document.removeEventListener('scroll', handler);
    };
  }, [ref, callback]);
}
