import { useContext } from 'react';

import PhotoRoutes from '../PhotoRoutes';
import { AlbumDetailContext, AlbumDetailProvider } from './AlbumDetailContext';
import AlbumDetailHooks from './AlbumDetailHooks';
import AlbumFriendSelectModal from './components/AlbumFriendSelectModal';
import AlbumInfoView from './components/AlbumInfoView';
import AlbumRenameModal from './components/AlbumRenameModal';
import SearchOptionList from './components/SearchOptionList';
import { useFlattenPageData, useTotal } from 'src/api/generate-infinite-query';
import albumDetailApi from 'src/api/photo/album-detail';
import albumPhotosApi from 'src/api/photo/album-photos';
import ListLoadingIndicator from 'src/components/common/ListLoadingIndicator';
import { Loading } from 'src/components/common/LoadingSuspense';
import CommonLayout from 'src/components/common/layout/CommonLayout';
import PhotoListView from 'src/components/photo/photo-list/PhotoListView';
import { PhotoSelectProvider } from 'src/components/photo/photo-list/PhotoSelectContext';
import { useScrollBottom } from 'src/hooks/scroll-bottom';
import { PhotoPreview } from 'src/model/photo';
import NotFoundPage from 'src/pages/common/NotFoundPage';
import AlbumPhotoSearchModal from 'src/pages/photo/album-detail/components/AlbumPhotoSearchModal';
import AlbumPhotoRegisterSelectModal from 'src/pages/photo/photo-list/components/AlbumPhotoRegisterSelectModal';

function AlbumDetailPage() {
  const { albumId } = AlbumDetailHooks.usePageParams();

  // hooks
  const [{ photoSearchParams }] = useContext(AlbumDetailContext);

  const { data: album, isLoading } = albumDetailApi.useApiResult({ albumId });
  const { data, fetchNextPage, isFetching } = albumPhotosApi.useInfiniteApi(
    { albumId, ...photoSearchParams },
    { suspense: false },
  );
  const photos = useFlattenPageData(data);
  const total = useTotal(data);

  useScrollBottom(() => {
    if (!isFetching) {
      fetchNextPage();
    }
  });

  const headerProps = AlbumDetailHooks.useHeaderProps(album ?? undefined);

  const itemHref = (v: PhotoPreview) => PhotoRoutes.albumViewer({ albumId, photoId: v.id });

  let content = <></>;
  if (isLoading) {
    content = <Loading />;
  } else if (!album) {
    return <NotFoundPage />;
  } else {
    content = (
      <div className="d-grid gap-3">
        <AlbumInfoView album={album} total={total} />
        <SearchOptionList members={album.members} />
        <PhotoListView photos={photos} itemHref={itemHref} />
        <ListLoadingIndicator isFetching={isFetching} />
        <AlbumPhotoRegisterSelectModal />
        <AlbumPhotoSearchModal members={album.members} />
        <AlbumRenameModal name={album.name} />
        <AlbumFriendSelectModal members={album.members} />
      </div>
    );
  }

  return (
    <CommonLayout className="AlbumDetailPage" {...headerProps} noContainer>
      {content}
    </CommonLayout>
  );
}

export default function AlbumDetailIndex() {
  return (
    <PhotoSelectProvider>
      <AlbumDetailProvider>
        <AlbumDetailPage />
      </AlbumDetailProvider>
    </PhotoSelectProvider>
  );
}
