import { generateApi } from '../generate-api';
import photoDownloadListApi from './photo-download-list';

export interface PhotoDownloadParams {
  photoIds: string[];
  albumId?: string;
}

export interface PhotoDownloadResult {
  result: string;
}

const photoDownloadApi = generateApi<PhotoDownloadParams, PhotoDownloadResult>({
  api: (params) => ({
    url: `/api/v1/download`,
    method: 'POST',
    data: params,
  }),
  postHandle: () => {
    photoDownloadListApi.invalidate();
  },
});

export default photoDownloadApi;
