import { t } from 'i18next';

import DiaryRoutes from '../../DiaryRoutes';
import { DiaryStatsLengthMax } from 'src/api/diary/diary-stats';
import { numberWithComma } from 'src/utils';

interface Props {
  data: DiaryStatsLengthMax;
}

export default function DiaryStatsMaxItem(props: Props) {
  const { data } = props;

  const href = DiaryRoutes.detail({ date: data.date });

  return (
    <li>
      <div className="d-flex">
        <a href={href} className="DiaryStatsMaxItem d-flex gap-2 gray_on_hover">
          <div>{t('letters', { cnt: numberWithComma(data.length) })}</div>
          <div> - </div>
          <div>{data.date}</div>
        </a>
      </div>
    </li>
  );
}
