import { t } from 'i18next';
import { Button, Form, Modal } from 'react-bootstrap';
import { useController, useForm } from 'react-hook-form';

import ChronoUnitSelect from 'src/components/common/select/ChronoUnitSelect';
import { ChronoUnit } from 'src/model/api';

import './PhotoUpdateDateBulkModal.scss';

interface Props {
  show: boolean;
  onHide: () => void;
  callback: (state: PhotoUpdateDateBulkFormState) => void;
}

export interface PhotoUpdateDateBulkFormState {
  amount: number;
  unit: ChronoUnit;
}

export default function PhotoUpdateDateBulkModal(props: Props) {
  const { show, onHide, callback } = props;

  const defaultValues = useDefaultValues();
  const { register, handleSubmit, control } = useForm<PhotoUpdateDateBulkFormState>({ defaultValues });
  const { field: unitField } = useController({ name: 'unit', control });

  const onSubmit = (state: PhotoUpdateDateBulkFormState) => {
    onHide();
    callback(state);
  };

  return (
    <Modal className="PhotoUpdateDateBulkModal" centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('PhotoUpdateDateBulkModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>{t('PhotoUpdateDateBulkModal.time-label')}</Form.Label>
            <div className="time_container">
              <Form.Control type="number" {...register('amount', { valueAsNumber: true })} />
              <ChronoUnitSelect onChange={unitField.onChange} value={unitField.value} />
            </div>
          </Form.Group>
          <Button variant="primary" type="submit">
            {t('update')}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

function useDefaultValues(): PhotoUpdateDateBulkFormState {
  return {
    amount: 0,
    unit: 'HOURS',
  };
}
