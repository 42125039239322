import queryString from 'query-string';

import { GeneticCreatureDetailPageParams } from './detail/GeneticCreatureDetailHooks';
import { GeneticCreatureUpdatePageParams } from 'src/pages/genetic/update/GeneticCreatureUpdateHooks';

const { stringifyUrl } = queryString;

const GeneticRoutes = {
  listRoute: '/creatures',

  createRoute: '/creatures/create',

  detailRoute: '/creatures/:creatureId',
  detail: ({ creatureId, ...query }: GeneticCreatureDetailPageParams) =>
    stringifyUrl({ url: `/creatures/${creatureId}`, query }),

  updateRoute: '/creatures/:creatureId/update',
  update: ({ creatureId, ...query }: GeneticCreatureUpdatePageParams) =>
    stringifyUrl({ url: `/creatures/${creatureId}/update`, query }),

  combinationRoute: '/combination',
  reproduceRoute: '/reproduce',
  childrenRoute: '/children',
};

export default GeneticRoutes;
