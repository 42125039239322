import { t } from 'i18next';
import { decode, encodeURI } from 'js-base64';
import { useContext } from 'react';

import PhotoRoutes from '../PhotoRoutes';
import { PhotoListContext } from './PhotoListContext';
import albumPhotoRegisterApi from 'src/api/photo/album-photo-create';
import photoDeleteApi from 'src/api/photo/photo-delete-bulk';
import photoDownloadApi from 'src/api/photo/photo-download';
import { PhotoSearchParams } from 'src/api/photo/photo-search';
import photoUpdateDateBulkApi from 'src/api/photo/photo-update-date-bulk';
import { PhotoUpdateDateBulkFormState } from 'src/components/photo/modal/PhotoUpdateDateBulkModal';
import { PhotoSelectContext } from 'src/components/photo/photo-list/PhotoSelectContext';
import PhotoSelectHeaderHooks from 'src/components/photo/photo-list/PhotoSelectHeaderHooks';
import PhotoSelectHooks from 'src/components/photo/photo-list/PhotoSelectHooks';
import { useOptionalUrlParams } from 'src/hooks/url-params';
import { HeaderButton, HeaderMoreButton, HeaderProps } from 'src/model/component';
import { AlbumPreview } from 'src/model/photo';
import router from 'src/pages/router';
import { dispatch } from 'src/redux';
import { GlobalActions } from 'src/redux/global';
import { useContextSetter } from 'src/utils/context';

export interface PhotoListPageParams {
  search?: string;
}

function usePageParams(): PhotoListPageParams {
  const [search] = useOptionalUrlParams('search');
  return { search };
}

function useSearchParams(): PhotoSearchParams {
  const { search } = usePageParams();
  return search ? JSON.parse(decode(search)) : {};
}

function useSearch() {
  const setState = useContextSetter(PhotoListContext);
  const pageParams = usePageParams();

  return (params: PhotoSearchParams) => {
    setState({ showSearchModal: false });

    const { dateRange, name, orphan } = params;

    const searchParams: PhotoSearchParams = {
      dateRange: dateRange?.start && dateRange.end ? dateRange : undefined,
      name: name ? name : undefined,
      orphan: orphan ? orphan : undefined,
    };

    const newPageParams: PhotoListPageParams = {
      ...pageParams,
      search: encodeURI(JSON.stringify(searchParams)),
    };

    router.navigate(PhotoRoutes.photos(newPageParams));
  };
}

function useDelete() {
  const setPageState = useContextSetter(PhotoListContext);
  const [{ selects }] = useContext(PhotoSelectContext);
  const clear = PhotoSelectHooks.useClear();

  return async () => {
    const cnt = selects.length;
    if (!window.confirm(t('photo.msg.photo-delete-confirm', { cnt }))) {
      return;
    }

    const photoIds = selects.map((v) => v.id);

    dispatch(GlobalActions.update({ loading: true }));

    await photoDeleteApi({ photoIds });

    dispatch(GlobalActions.update({ loading: false }));

    setPageState({ selectMode: undefined });
    clear();
  };
}

function useShowAlbumSelectModal() {
  const setPageState = useContextSetter(PhotoListContext);

  return async () => {
    setPageState({ showAlbumSelectModal: true });
  };
}

function useShowDateUpdateModal() {
  const setPageState = useContextSetter(PhotoListContext);

  return async () => {
    setPageState({ showDateUpdateModal: true });
  };
}

function useAddToAlbum() {
  const setPageState = useContextSetter(PhotoListContext);
  const [{ selects }] = useContext(PhotoSelectContext);
  const clear = PhotoSelectHooks.useClear();

  return async (album: AlbumPreview) => {
    const photoIds = selects.map((v) => v.id);

    dispatch(GlobalActions.update({ loading: true }));

    await albumPhotoRegisterApi({ albumId: album.id, photoIds });

    dispatch(GlobalActions.update({ loading: false }));

    setPageState({ selectMode: undefined, showAlbumSelectModal: false });
    clear();

    router.navigate(PhotoRoutes.albumDetail(album.id));
  };
}

function useDownload() {
  const setPageState = useContextSetter(PhotoListContext);
  const [{ selects }] = useContext(PhotoSelectContext);
  const clear = PhotoSelectHooks.useClear();

  return async () => {
    const photoIds = selects.map((v) => v.id);

    dispatch(GlobalActions.update({ loading: true }));

    await photoDownloadApi({ photoIds });

    dispatch(GlobalActions.update({ loading: false }));

    setPageState({ selectMode: undefined });
    clear();

    router.navigate(PhotoRoutes.downloadRoute);
  };
}

function useUpdateDateBulk() {
  const setPageState = useContextSetter(PhotoListContext);
  const [{ selects }] = useContext(PhotoSelectContext);
  const clear = PhotoSelectHooks.useClear();

  return async (state: PhotoUpdateDateBulkFormState) => {
    const photoIds = selects.map((v) => v.id);

    dispatch(GlobalActions.update({ loading: true }));

    await photoUpdateDateBulkApi({
      photoIds,
      amount: state.amount,
      unit: state.unit,
    });

    dispatch(GlobalActions.update({ loading: false }));

    setPageState({ selectMode: undefined, showAlbumSelectModal: false });
    clear();
  };
}

function useSelectComplete() {
  const [{ selectMode }] = useContext(PhotoListContext);

  const deletePhoto = useDelete();
  const showAlbumSelectModal = useShowAlbumSelectModal();
  const showDateUpdateModal = useShowDateUpdateModal();
  const download = useDownload();

  switch (selectMode) {
    case 'delete':
      return deletePhoto;
    case 'album':
      return showAlbumSelectModal;
    case 'download':
      return download;
    case 'update-date':
      return showDateUpdateModal;
    default:
      return undefined;
  }
}

function useHeaderProps(): HeaderProps {
  const setPageState = useContextSetter(PhotoListContext);
  const [{ selectMode }, setSelectState] = useContext(PhotoSelectContext);

  const onComplete = useSelectComplete();
  const headerPropsOnSelect = PhotoSelectHeaderHooks.useHeaderProps(onComplete);

  if (selectMode) {
    return headerPropsOnSelect;
  }

  const title = t('PhotoListPage.title');

  const btns: HeaderButton[] = [
    {
      icon: 'fas fa-upload',
      name: t('photo.page.album-detail.upload'),
      onClick: () => router.navigate(PhotoRoutes.photoUpload),
    },
  ];

  const menus: HeaderMoreButton[] = [
    {
      icon: 'fas fa-search',
      name: t('search'),
      onClick: () => setPageState({ showSearchModal: true }),
    },
    {
      icon: 'fas fa-trash',
      name: t('delete'),
      onClick: () => {
        setPageState({ selectMode: 'delete' });
        setSelectState({ selectMode: true });
      },
    },
    {
      icon: 'fas fa-plus',
      name: t('PhotoListView.add-to-album'),
      onClick: () => {
        setPageState({ selectMode: 'album' });
        setSelectState({ selectMode: true });
      },
    },
    {
      icon: 'fas fa-clock',
      name: t('photo.menu.update-date'),
      onClick: () => {
        setPageState({ selectMode: 'update-date' });
        setSelectState({ selectMode: true });
      },
    },
    {
      icon: 'fas fa-download',
      name: t('download'),
      onClick: () => {
        setPageState({ selectMode: 'download' });
        setSelectState({ selectMode: true });
      },
    },
    {
      icon: 'fas fa-sort-alpha-down',
      name: t('photo.term.sort-photos'),
      onClick: () => alert('not yet supported!'),
    },
  ];

  return {
    title,
    btns,
    menus,
  };
}

const PhotoListHooks = {
  usePageParams,
  useHeaderProps,
  useSearch,
  useSearchParams,
  useAddToAlbum,
  useUpdateDateBulk,
};

export default PhotoListHooks;
