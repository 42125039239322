import { t } from 'i18next';

import CommonNavigation from 'src/components/common/navigation/CommonNavigation';
import { NavigationProps } from 'src/model/component';
import GeneticRoutes from 'src/pages/genetic/GeneticRoutes';

const props: NavigationProps = {
  title: 'HyunGenetic',
  menus: [
    {
      name: t('GeneticNavigation.list'),
      link: GeneticRoutes.listRoute,
      icon: 'fas fa-list',
    },
    {
      name: t('GeneticNavigation.combination'),
      link: GeneticRoutes.combinationRoute,
      icon: 'fas fa-network-wired',
    },
    {
      name: t('GeneticNavigation.reproduce'),
      link: GeneticRoutes.reproduceRoute,
      icon: 'fas fa-plus',
    },
    {
      name: t('GeneticNavigation.children'),
      link: GeneticRoutes.childrenRoute,
      icon: 'fas fa-child',
    },
  ],
  disableSearch: true,
};

export default function GeneticNavigation() {
  return <CommonNavigation {...props} />;
}
