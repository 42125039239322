import { t } from 'i18next';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import DiaryDetailHooks from '../DiaryDetailHooks';
import DiaryDetailContent from './DiaryDetailContent';
import DiaryDetailPhotoList from './DiaryDetailPhotoList';
import { Diary } from 'src/model/diary';
import DiaryRoutes from 'src/pages/diary/DiaryRoutes';
import DiaryMeetFriendView from 'src/pages/diary/detail/components/DiaryMeetFriendView';

interface Props {
  diary?: Diary | null;
}

export default function DiaryDetailView({ diary }: Props) {
  const { date } = DiaryDetailHooks.usePageParams();

  if (!diary) {
    const createUrl = DiaryRoutes.create({ date });

    return (
      <div className="DiaryDetailView">
        <p>{t('DiaryDetailPage.empty-msg')}</p>
        <Link to={createUrl}>
          <Button>{t('DiaryCalendarPage.create')}</Button>
        </Link>
      </div>
    );
  }

  const { summary, content } = diary;

  return (
    <div className="DiaryDetailView">
      <p>{summary}</p>
      <DiaryMeetFriendView className="mb-3" meetFriends={diary.friends} />
      <DiaryDetailContent content={content} />
      {diary && <DiaryDetailPhotoList diary={diary} />}
      <hr />
    </div>
  );
}
