import cs from 'classnames';
import { t } from 'i18next';
import { useRef, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';

import { useElementScrollTop } from 'src/hooks/scroll-bottom';
import { HeaderProps } from 'src/model/component';
import router from 'src/pages/router';
import { useBreakpointMobile } from 'src/utils/breakpoint';

import './DesktopHeader.scss';

export default function DesktopHeader(props: HeaderProps) {
  const { title, back, onClose } = props;
  const btns = props.btns ?? [];
  const menus = props.menus ?? [];
  const isMobile = useBreakpointMobile();
  const ref = useRef<HTMLDivElement>(null);
  const [isTop, setTop] = useState(false);

  useElementScrollTop(ref, (isTop) => {
    setTop(isTop);
  });

  const onBack = () => {
    if (back) {
      router.navigate(-1);
    }

    if (onClose) {
      onClose();
    }
  };

  const buttons = btns.map((v) => (
    <Button key={v.icon} onClick={v.onClick}>
      <i className={v.icon} />
      {v.name && <span className="ms-2">{v.name}</span>}
    </Button>
  ));

  const dropdownItems: React.ReactNode[] = [];

  if (menus.length <= 4) {
    const moreButtons = menus.map((v) => (
      <Button key={v.name} onClick={v.onClick}>
        {v.icon && <i className={v.icon} />}
        <span className="ms-2">{v.name}</span>
      </Button>
    ));

    buttons.push(...moreButtons);
  } else {
    const moreButtons = menus.map((v) => (
      <Dropdown.Item key={v.name} onClick={v.onClick}>
        {v.icon && <i className={v.icon} />}
        <span className="ms-2">{v.name}</span>
      </Dropdown.Item>
    ));

    dropdownItems.push(...moreButtons);
  }

  if (isMobile) {
    return <></>;
  }

  return (
    <div className={cs('DesktopHeader', { isTop })} ref={ref}>
      <div className="hyunsub_container">
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="mb-0">
            {back && <i className="fas fa-chevron-left me-3" style={{ cursor: 'pointer' }} onClick={onBack}></i>}
            {onClose && <i className="fas fa-times me-3" style={{ cursor: 'pointer' }} onClick={onBack}></i>}
            <span>{title}</span>
          </h1>
          <div className="d-flex gap-2">
            {buttons}
            {dropdownItems.length > 0 && (
              <Dropdown data-bs-theme="dark" align="end">
                <Dropdown.Toggle id="dropdown-basic">
                  <i className="fas fa-bars" />
                  <span className="ms-2">{t('CommonNavigation.menu')}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>{dropdownItems}</Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
