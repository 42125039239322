import { t } from 'i18next';
import { useState } from 'react';

import GeneticChildrenResultView from './components/GeneticChildrenResultView';
import { useFlattenPageData } from 'src/api/generate-infinite-query';
import geneticCreatureSearchApi from 'src/api/genetic/genetic-creature-search';
import CommonLayout from 'src/components/common/layout/CommonLayout';
import GeneticParentForm, { GeneticParentFormState } from 'src/components/genetic/form/GeneticParentForm';

export default function GeneticChildrenPage() {
  const [formState, setFormState] = useState<GeneticParentFormState>();
  const { data } = geneticCreatureSearchApi.useInfiniteApi({});
  const creatures = useFlattenPageData(data);

  return (
    <CommonLayout className="GeneticChildrenPage" title={t('GeneticNavigation.children')}>
      <GeneticParentForm creatures={creatures} onSubmit={setFormState} />
      {formState && <GeneticChildrenResultView formState={formState} />}
    </CommonLayout>
  );
}
