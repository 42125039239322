import { t } from 'i18next';

import CommonDescription from 'src/components/common/description/CommonDescription';
import { GeneticCreature } from 'src/model/genetic';

interface Props {
  creature: GeneticCreature;
}

export default function GeneticCreatureDetailView(props: Props) {
  const { creature } = props;
  const { birthday, punch, isMale, type1, type2, type3, type4 } = creature;

  return (
    <div className="GeneticCreatureDetailView d-grid gap-2">
      <CommonDescription.Group>
        <CommonDescription label={t('GeneticCreature.birthday')} value={birthday} />
        <CommonDescription label={t('GeneticCreature.punch')} value={punch} />
        <CommonDescription label={t('GeneticCreature.isMale')} value={isMale ? 'M' : 'F'} />
      </CommonDescription.Group>
      <CommonDescription.Group>
        <CommonDescription label={t('genetic.type1')} value={type1} />
        <CommonDescription label={t('genetic.type2')} value={type2} />
        <CommonDescription label={t('genetic.type3')} value={type3} />
        <CommonDescription label={t('genetic.type4')} value={type4} />
      </CommonDescription.Group>
    </div>
  );
}
