import { t } from 'i18next';

import CommonRadioButton from 'src/components/common/form/CommonRadioButton';

const data = [true, false];

interface Props {
  onChange: (isMale: boolean) => void;
  value?: boolean;
}

export default function GenderRadioButton(props: Props) {
  return (
    <CommonRadioButton
      data={data}
      labelSelector={(v) => (v ? t('male') : t('female'))}
      onChange={props.onChange}
      value={props.value}
    />
  );
}
