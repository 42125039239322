import { t } from 'i18next';

import PhotoDownloadHooks from './PhotoDownloadHooks';
import PhotoDownloadList from './components/PhotoDownloadList';
import photoDownloadListApi from 'src/api/photo/photo-download-list';
import CommonPagination from 'src/components/common/CommonPagination';
import CommonLayout from 'src/components/common/layout/CommonLayout';
import { PageData } from 'src/model/api';
import { PhotoDownload } from 'src/model/photo';

import './PhotoDownloadPage.scss';

export default function PhotoDownloadPage() {
  const { page } = PhotoDownloadHooks.usePageParams();
  const { data } = photoDownloadListApi.useApiResult({ page }, { suspense: false });
  const pagination = usePagination(data);

  return (
    <CommonLayout className="PhotoDownloadPage" title={t('PhotoNavigation.download')}>
      {pagination}
      <PhotoDownloadList data={data} />
      {pagination}
    </CommonLayout>
  );
}

function usePagination(data?: PageData<PhotoDownload>): JSX.Element {
  const setPageParams = PhotoDownloadHooks.useSetPageParams();

  if (!data) {
    return <></>;
  }

  const setPage = (page: number) => {
    setPageParams({ page });
  };

  const { total, page, pageSize } = data;
  const totalPage = Math.floor((total - 1) / pageSize) + 1;
  return <CommonPagination now={page} total={totalPage} onClick={setPage} />;
}
