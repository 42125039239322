const en = {
  translation: {
    modify: 'Modify',
    edit: 'Edit',
    delete: 'Delete',
    view: 'View',
    add: 'Add',
    logout: 'Logout',
    menus: 'Menus',
    search: 'Search',
    close: 'Close',
    setting: 'Setting',
    error: 'Error',
    select: 'Select',
    cancel: 'Cancel',
    create: 'Create',
    upload: 'Upload',
    update: 'Update',
    sort: 'Sort',
    filter: 'Filter',
    download: 'Download',
    complete: 'Complete',
    query: 'Query',
    yesterday: 'Yesterday',
    tomorrow: 'Tomorrow',
    applications: 'Applications',
    profile: 'Profile',
    letters: '{{cnt}} letters',
    invite: 'Invite',
    reset: 'Reset',
    male: 'Male',
    female: 'Female',
    'n-selected': '{{0}} Selected',
    'no-selection': 'No Selection',
    'period.year': '{{years}} Years',
    'period.month': '{{months}} Months',
    'period.day': '{{days}} Days',

    'msg.delete-confirm': 'Are you sure you want to delete?',
    'msg.delete-success': 'Successfully deleted',
    'msg.type-query': 'Please type search query...',

    'common.403.oh': 'Huh?!',
    'common.403.not-exist': 'Cannot access to this page',
    'common.403.go-to-home': 'Go to home',
    'common.404.oh': 'Ah!',
    'common.404.not-exist': 'This page is not exist',
    'common.404.go-to-home': 'Go to Home',
    'common.500.oh': 'Ouch!',
    'common.500.error': 'Unexpeced error occurred',
    'common.500.go-to-home': 'Go to Home',
    'CommonNavigation.search': 'Search',
    'CommonNavigation.menu': 'Menu',
    'CommonSearchResult.empty-result': 'There is no search result',
    'CommonSearchResult.result-num': '{{total}} Results Found',
    'CommonSearchResult.search-status': '{{total}} Results Found for {{query}}',
    'common.form.feedback.required': 'Please input the value',
    'common.form.feedback.no-minus': 'Cannot input minus value',
    'common.select.please-select': 'Please select the value',

    'upload.status': '{{now}} / {{total}} Completed',
    'upload.ready': 'Ready',
    'upload.upload': 'Upload',
    'upload.abort': 'Abort',
    'upload.aborted': 'Aborted',
    'upload.completed': 'Completed',
    'upload.clear': 'Clear',

    'auth.login': 'Login',
    'auth.logout': 'Logout',
    'auth.login-persist': 'Keep Login',
    'auth.register': 'Register',
    'auth.sign-out': 'Withdrawal',
    'auth.sign-out.confirm': 'Are you sure you want to sign-out?',
    'auth.sign-out.success': 'Successful sign-out',
    'auth.id': 'ID',
    'auth.pw': 'Password',
    'auth.pw-confirm': 'Password Confirm',
    'auth.errMsg.empty-id': 'Please type your ID',
    'auth.errMsg.empty-pw': 'Please type your password',
    'auth.errMsg.short-id': 'ID should be longer than 3 characters',
    'auth.errMsg.long-id': 'ID should be shorter than 21 characters',
    'auth.errMsg.wrong-pattern-id': 'ID should be consist of only alphabet and numbers',
    'auth.errMsg.short-pw': 'Password should be longer than 7 characters',
    'auth.errMsg.long-pw': 'Password should be shorter than 101 charcaters',
    'auth.errMsg.not-equal-pw': 'Password is not equal',
    'auth.errMsg.invalid-id-pw': 'Wrong ID or password',
    'auth.errMsg.already-exist-id': 'Already using ID',
    'auth.errMsg.captcha-required': 'Captcha Required',
    'auth.msg.register-success': 'Register Success! Please login with the information you just signed up for',
    'auth.api.valid-url.failure': 'Illegal Access',
    'auth.my-page.title': 'Manage My Account',
    'auth.my-page.login-info': 'Login Information',
    'auth.my-page.login-status': 'Login Status',
    'auth.my-page.login-history': 'Login History',
    'auth.my-page.login-history-num': '{{0}} cases',
    'auth.my-page.login-device': 'Logined Devices',
    'auth.my-page.login-device-num': '{{0}} devices',
    'auth.modify-username-modal.title': 'Modify ID',
    'auth.modify-password-modal.title': 'Modify Password',
    'auth.ProfilePage.language': 'Language Setting',
    'auth.ProfilePasswordForm.old-pw': 'Old Password',
    'auth.ProfilePasswordForm.new-pw-1': 'New Password',
    'auth.ProfilePasswordForm.new-pw-2': 'New Password (confirm)',
    'auth.auth-status.exist-id': 'Already using ID',
    'auth.auth-status.invalid-length-id': 'Id length should be 8 ~ 255',
    'auth.auth-status.invalid-pattern-id': 'ID should be consist of only alphabet and numbers',
    'auth.auth-status.invalid-length-password': 'Password length should be 8 ~ 255',
    'auth.auth-status.wrong-password': 'Wrong Password',
    'auth.auth-status.current-password': 'Same as current password',
    'auth.auth-status.not-exist-user-or-password': 'Wrong ID or password',

    'video.term.sort.random': 'Random',
    'video.term.sort.new': 'Recently Uploads',
    'video.term.sort.old': 'Old Uploads',
    'video.term.sort.abc': 'Name Ascending',
    'video.term.sort.zyx': 'Name Descending',
    'video.episode-section.title': 'Total {{0}} episodes',
    'video.group-section.title': 'List of {{0}}',
    'video.setting-section.title': 'Video Play Setting',
    'video.VideoSettingSubtitleSizeCard.title': 'Subtitle Size Setting',
    'video.VideoSettingSubtitleSizeCard.label': 'Current Subtitle Font Size: {{0}}px',
    'video.VideoSettingSubtitleSyncCard.title': 'Subtitle Sync Setting',
    'VideoTabBar.home': 'Home',
    'VideoTabBar.history': 'History',
    'VideoHomePage.title': 'Home Page',
    'VideoHomePage.more': 'More >',
    'VideoSearchPage.title': 'Search Video',
    'VideoSearchPage.result-title': `{{1}} results found for '{{0}}'`,

    'photo.page.album-list.title': 'My Album List',
    'photo.page.album-detail.title': 'Photo List - {{0}}',
    'photo.page.album-detail.upload': 'Upload Photos',
    'AlbumInfoView.photo-num': '{{cnt}} Photos',
    'AlbumViewerPage.title': 'Album Viewer',
    'photo.page.album-upload.title': '{{0}} - Photo Upload',
    'photo.page.album-upload.back-to-album': 'Back to album',
    'photo.page.album-upload.upload': 'Upload',
    'photo.page.album-upload.msg.empty-upload': 'Please select files to upload',
    'PhotoListPage.title': 'My Photo List',
    'photo.page.setting.title': 'Setting',
    'photo.term.photo-dimension': 'Dimension',
    'photo.term.photo-date': 'Date',
    'photo.term.photo-size': 'Size',
    'PhotoNavigation.album': 'Albums',
    'PhotoNavigation.photo': 'Photos',
    'PhotoNavigation.upload': 'Upload',
    'PhotoNavigation.download': 'Download',
    'PhotoNavigation.share': 'Share',
    'PhotoUploadPage.title': 'Photo Upload',
    'PhotoUploadPage.photo-num': '{{0}} Photos',
    'PhotoListView.select-all': 'Select All',
    'PhotoListView.unselect-all': 'Unselect All',
    'PhotoListView.add-to-album': 'Add to Album',
    'PhotoListView.delete-from-album': 'Delete Photo',
    'PhotoListView.delete-photo': 'Completely Delete',
    'photo.album-select': 'Select Album',
    'AlbumCreateModal.title': 'Create Album',
    'AlbumCreateModal.name': 'Album Name',
    'photo.register-thumbnail': 'Register Thumbnail',
    'photo.album-delete-confirm': 'Are you sure you want to delete the album?',
    'PhotoInfoSection.image-size': 'Resolution',
    'PhotoInfoSection.file-size': 'File Size',
    'PhotoInfoSection.date': 'Filming Date',
    'PhotoInfoSection.file-name': 'File Name',
    'PhotoInfoSection.reg-dt': 'Upload Date',
    'PhotoInfoSection.date-type': 'Filming Date Type',
    'AlbumDetailPage.delete-album': 'Delete Album',
    'PhotoInfoSection.go-to-original': 'View Original',
    'PhotoInfoSection.go-to-detail': 'Get Detail Info',
    'PhotoDetailPage.title': 'Photo Detail',
    'PhotoDateModal.title': 'Update Filming Date',
    'photo.view-as-date': 'View as date',
    'AlbumInfoView.members': 'Album Members',
    'PhotoSearchModal.title': 'Search Photo',
    'PhotoSearchForm.date-range': 'Date Range',
    'PhotoSearchForm.orphan': 'Show photos only not registered to album',
    'photo.filter.name': 'File Name: {{name}}',
    'photo.term.rename-album': 'Edit Album Name',
    'photo.term.album-name': 'Album Name',
    'photo.term.sort-photos': 'Sort Photos',
    'photo.term.invite-to-album': 'Invite Friends',
    'photo.term.photo-name': 'Photo File Name',
    'photo.term.search-filter': 'Search Filter : ',
    'photo.term.album-share-setting': 'Album Share Setting',
    'photo.msg.photo-delete-confirm':
      'Are you sure you want to delete {{cnt}} pictures? They will also be deleted from the album.',
    'photo.msg.photo-delete-from-album-confirm':
      'Are you sure you want to delete {{cnt}} pictures from this album? They will remain the same on other albums or on my photo list.',
    'PhotoDownloadList.no': 'No',
    'PhotoDownloadList.content': 'Content',
    'PhotoDownloadList.status': 'Status',
    'PhotoDownloadList.date': 'Date',
    'PhotoDownloadList.content-data': '{{total}} Photos including {{content}}',
    'PhotoUpdateDateBulkModal.title': 'Update Photo Date Bulk',
    'PhotoUpdateDateBulkModal.time-label': 'Amount of time',
    'photo.menu.update-date': 'Update Photo Date Bulk',

    'apparel.page.list.title': 'My Apparel List',
    'apparel.page.list.inner-title': 'Total {{total}} items',
    'apparel.page.detail.title': 'Apparel Detail',
    'apparel.page.edit.title': 'Edit - {{0}}',
    'ApparelCreatePage.title': 'Add My Apparel',
    'apparel.page.category-list.title': 'Apparel Category List',
    'apparel.page.category-detail.title': 'Apparel Category - {{0}} ({{1}} items)',
    'apparel.page.brand-list.title': 'Apparel Brand List',
    'apparel.page.brand-detail.title': 'Apparel Brand - {{0}} ({{1}} items)',
    'ApparelNavigation.all-apparels': 'All Apparels',
    'ApparelNavigation.categories': 'Categories',
    'ApparelNavigation.brands': 'Brands',
    'apparel.term.name': 'Name',
    'apparel.term.itemNo': 'Item No',
    'apparel.term.brand': 'Brand',
    'apparel.term.category': 'Category',
    'apparel.term.size': 'Size',
    'apparel.term.color': 'Color',
    'apparel.term.originPrice': 'Origin Price',
    'apparel.term.discountPrice': 'Discount Price',
    'apparel.term.buyDt': 'Buy Date',
    'apparel.term.buyLoc': 'Buy Location',
    'apparel.term.material': 'Materials',
    'apparel.term.size2': 'Size Detail',
    'apparel.term.makeDt': 'Made Date',
    'apparel.term.discarded': 'Discarded',

    'DriveExplorerPage.title': 'Explorer',
    'drive.msg.remove-confirm': 'Are you sure you want to delete it?',
    DriveNavigation: 'Explorer',
    'drive.preview.files-selected': '{{0}} selected',
    'drive.rename.replace': 'Replace String',
    'drive.rename.replace.button': 'Replace',
    'drive.rename.add-number': 'Add Number',
    'drive.rename.pad-number': 'Pad Number',
    'drive.rename.number.start-number': 'Start Number',
    'drive.rename.number.digit': 'Digit',
    'drive.rename.add-number.front': 'Add to Front',
    'drive.rename.add-number.back': 'Add to Back',
    'drive.rename.pad-number.button': 'Pad Number',
    'drive.rename.reset': 'Reset',
    'drive.rename.apply': 'Apply in practice',
    'drive.DriveUploadButton.upload': 'Upload',
    'drive.DriveUploadButton.upload-files': 'Upload Files',
    'drive.DriveUploadButton.upload-folder': 'Upload Folder',
    'drive.DriveNewFolderModal.title': 'Create New Folder',
    'drive.DriveNewFolderModal.label': 'Folder Name',
    'drive.DriveNewFolderModal.button': 'Create',
    'drive.DriveRenameModal.title': 'Edit File Name Bulk',
    'drive.DriveRenameModal.label': 'File Name',
    'drive.DriveRenameModal.button': 'Edit',
    'drive.DriveExplorerBreadcrumb.home': 'Home',
    'drive.DriveExplorerFileList.type': 'Type',
    'drive.DriveExplorerFileList.name': 'Name',
    'drive.DriveExplorerFileList.size': 'Size',
    'drive.DriveExplorerFileList.date': 'Modified Date',
    'drive.DriveExplorerFileList.new-folder': 'New Folder',
    'drive.DriveExplorerFileList.empty-folder': 'Empty Folder',
    'drive.DriveExplorerFileList.n-files': '{{0}} Files',
    'drive.DriveUploadModal.abort-msg': 'Are you sure to cancel upload?',
    'drive.YoutubeDownloadModal.title': 'YouTube Download',
    'drive.YoutubeDownloadModal.msg.empty-url': 'Please input URL',
    'drive.YoutubeMetadataForm.resolution': 'Resolution',
    'drive.YoutubeMetadataForm.subtitles': 'Subtitles',

    'comic.ComicListPage.title': 'Comic List',
    'comic.ComicDetailPage.subtitle': '{{0}} Episodes',
    'ComicNavigation.list': 'List',
    'ComicNavigation.history': 'History',

    'DiaryNavigation.list': 'List',
    'DiaryNavigation.calendar': 'Calendar',
    'DiaryNavigation.stats': 'Statistics',
    'DiaryListPage.title': 'Diary List',
    'DiaryCalendarPage.title': 'Diary Calendar',
    'DiaryCalendarPage.create': 'Write Diary in this day',
    'DiaryDetailPage.title': 'Diary Detail',
    'DiaryDetailPage.empty-msg': 'There is no diary in this day.',
    'DiaryDetailPage.friends': 'Meet Friends',
    'DiaryDetailPage.photos.title': '{{num}} Photos',
    'DiaryPhotoPage.title': 'Photos - {{date}}',
    'DiaryPhotoPage.subtitle': '{{total}} Photos',
    'DiaryCreatePage.title': 'Write Diary',
    'DiaryUpdatePage.title': 'Edit Diary',
    'DiarySearchPage.title': 'Search Diary',
    'DiaryForm.date': 'Date',
    'DiaryForm.summary': 'Summary',
    'DiaryForm.content': 'Content',
    'DiaryForm.friends': 'Friends',
    'DiaryStatsPage.total': 'Total of {{cnt}}',
    'DiaryStatsPage.date': 'Dates',
    'DiaryStatsPage.date.min': 'First Diary',
    'DiaryStatsPage.date.max': 'Last Diary',
    'DiaryStatsPage.date.total': 'Total Period',
    'DiaryStatsPage.date.avg.label': 'Average number',
    'DiaryStatsPage.date.avg.value': '{{cnt}} / day',
    'DiaryStatsPage.length': 'Letters',
    'DiaryStatsPage.length.avg': 'Average Letters',
    'DiaryStatsPage.length.total': 'Total Letters',
    'DiaryStatsPage.length.top5': 'Letters Top 5',
    'DiaryStatsPage.length.distribution': 'Letters Distribution',

    'FriendNavigation.list': 'List',
    'FriendNavigation.tags': 'Tags',
    'FriendListPage.title': 'Friend List',
    'FriendDetailPage.title': 'Friend Detail',
    'FriendDetailPage.delete-confirm': 'Are you sure you want to delete {{0}}?',
    'FriendCreatePage.title': 'Register Friend',
    'FriendUpdatePage.title': 'Modify Friend Information',
    'FriendSearchPage.title': 'Search Friend',
    'FriendForm.name': 'Name',
    'FriendForm.birthday': 'Birthday',
    'FriendForm.tags': 'Tags',
    'FriendForm.description': 'Memo',
    'FriendForm.meets': 'Meets',
    'FriendTagListPage.title': 'Tag List',
    'FriendTagDetailPage.title': 'Tag Detail - {{0}}',
    'friend.invite-friend': 'Invite Friend',
    'friend.friend-list': 'Friend List',

    'Dutch.should': 'Should',
    'Dutch.actual': 'Actual',
    'Dutch.amount': 'Amount',
    'Dutch.tripCurrency': 'Trip Currency',
    'Dutch.settleCurrency': 'Settle Currency',
    'Dutch.members': 'Members',
    'Dutch.currency-rate': 'Currency Rate',
    'Dutch.settle-currency-rate': 'Currency Rate for Settle',
    'Dutch.settle': 'Settle',
    'Dutch.cash-status': 'Cash Status',
    'Dutch.initial-balance': 'Initial Balance',
    'Dutch.spended-balance': 'Spended Amount',
    'Dutch.remaining-balance': 'Remaining Balance',
    'DutchPayment.card': 'Card',
    'DutchPayment.cash': 'Cash',
    'DutchHomePage.status': 'Status',
    'DutchNavigation.home': 'Home',
    'DutchNavigation.records': 'Records',
    'DutchNavigation.balance': 'Balance',
    'DutchNavigation.settle': 'Settlement',
    'DutchRecordListPage.title': 'Records',
    'DutchRecordDetailPage.title': 'Record Detail',
    'DutchRecordCreatePage.title': 'Record Create',
    'DutchRecordUpdatePage.title': 'Record Update',
    'DutchRecord.content': 'Content',
    'DutchRecord.location': 'Location',
    'DutchRecord.currency': 'Currency',
    'DutchRecord.payment': 'Payment',
    'DutchRecord.amount': 'Amount',
    'DutchRecord.date': 'Date',
    'DutchRecord.members': 'Shares',
    'DutchRecordMember.name': 'Name',
    'DutchSettlePage.leader': 'Leader',
    'DutchSettleResultView.message.send': '{{name}} should be send {{amount}}',
    'DutchSettleResultView.message.receive': '{{name}} should be receive {{amount}}',
    'DutchMemberSelectPage.message.choose': 'Choose yourself',
    'DutchSpendPage.select-member': 'Select Other User',
    'DutchRecordForm.feedback.invalid-sum': 'Should value and actual value is not same',
    'DutchBalanceUpdatePage.title': 'Edit Initial Balance',

    'GeneticNavigation.list': 'List',
    'GeneticNavigation.combination': 'Combination',
    'GeneticNavigation.reproduce': 'Reproduce',
    'GeneticNavigation.children': 'Children',
    'GeneticCreatureDetailPage.title': 'Detail - {{name}}',
    'CeneticCreatureCreatePage.title': 'Create',
    'CeneticCreatureUpdatePage.title': 'Update',
    'GeneticCreature.name': 'Name',
    'GeneticCreature.birthday': 'Birthday',
    'GeneticCreature.punch': 'Punch',
    'GeneticCreature.isMale': 'Gender',
    'genetic.type1': 'AQP5E',
    'genetic.type2': 'TLR9',
    'genetic.type3': 'S1PR2 Cre',
    'genetic.type4': 'Ai14',

    'PageSelectModal.title': 'Move Page',
    'PageSelectModal.close': 'Close',
    'PageSelectModal.move': 'Move',
    'PageSelectModal.msg.invalid-page': 'Are you sure you want to cancel the upload?',

    'menu.profile': 'Show Profile',
  },
};

export default en;
