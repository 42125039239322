import { t } from 'i18next';

import CommonDescription from 'src/components/common/description/CommonDescription';
import { ApparelInfo } from 'src/model/apparel';
import { numberWithComma } from 'src/utils';

interface Props {
  info: ApparelInfo;
}

export default function ApparelDetailView({ info: apparel }: Props) {
  return (
    <div className="mt-0 d-grid gap-3 row-col-2 row-col-sm-3 row-col-md-4 row-col-lg-5">
      <CommonDescription label={t('apparel.term.itemNo')} value={apparel.itemNo} />
      <CommonDescription label={t('apparel.term.brand')} value={apparel.brand} />
      <CommonDescription label={t('apparel.term.category')} value={apparel.category} />
      <CommonDescription label={t('apparel.term.size')} value={apparel.size} />
      <CommonDescription label={t('apparel.term.color')} value={apparel.color} />
      <CommonDescription label={t('apparel.term.originPrice')} value={numberWithComma(apparel.originPrice)} />
      <CommonDescription label={t('apparel.term.discountPrice')} value={numberWithComma(apparel.discountPrice)} />
      <CommonDescription label={t('apparel.term.buyDt')} value={apparel.buyDt} />
      <CommonDescription label={t('apparel.term.buyLoc')} value={apparel.buyLoc} />
      <CommonDescription label={t('apparel.term.makeDt')} value={apparel.makeDt} />
      <CommonDescription label={t('apparel.term.material')} value={apparel.material} />
      <CommonDescription label={t('apparel.term.size2')} value={apparel.size2} />
    </div>
  );
}
