import { generateApi } from 'src/api/generate-api';

export interface LogoutResult {
  success: boolean;
}

const logoutApi = generateApi<{}, LogoutResult>({
  api: () => ({
    url: '/api/v1/logout',
    method: 'POST',
    withCredentials: true,
  }),
  host: 'auth',
});

export default logoutApi;
