import { FieldPathValue } from 'node_modules/react-hook-form/dist/types';
import { FieldPath, FieldValues, UseControllerProps, useController } from 'react-hook-form';

export interface UseCustomControllerReturn<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  onChange: (...event: any[]) => void;
  value: FieldPathValue<TFieldValues, TName>;
  isInvalid: boolean;
}

export default function useCustomController<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: UseControllerProps<TFieldValues, TName>): UseCustomControllerReturn<TFieldValues, TName> {
  const { field, fieldState } = useController(props);
  const { onChange, value } = field;
  const { invalid } = fieldState;

  return {
    onChange,
    value,
    isInvalid: invalid,
  };
}
