import { generateApi } from '../generate-api';
import photoDetailApi from './photo-detail';
import photoSearchApi from 'src/api/photo/photo-search';
import { PhotoPreview } from 'src/model/photo';

export interface PhotoUpdateDateParams {
  photoId: string;
  date: string;
}

const photoUpdateDateApi = generateApi<PhotoUpdateDateParams, PhotoPreview>({
  api: (data) => ({
    url: `/api/v1/photos/${data.photoId}/date`,
    method: 'PUT',
    data,
  }),
  postHandle: (result, params) => {
    const newPhotoId = result.id;
    photoDetailApi.setCache({ photoId: newPhotoId }, result);
    photoSearchApi.invalidate();
  },
});

export default photoUpdateDateApi;
