import { t } from 'i18next';
import { useContext } from 'react';

import PhotoRoutes from '../PhotoRoutes';
import { AlbumDetailContext } from './AlbumDetailContext';
import albumDeleteApi from 'src/api/photo/album-delete';
import albumOwnerUpdateApi from 'src/api/photo/album-owner-update';
import albumPhotoDeleteApi from 'src/api/photo/album-photo-delete';
import albumUpdateApi from 'src/api/photo/album-update';
import photoDownloadApi from 'src/api/photo/photo-download';
import { PhotoSelectContext } from 'src/components/photo/photo-list/PhotoSelectContext';
import PhotoSelectHeaderHooks from 'src/components/photo/photo-list/PhotoSelectHeaderHooks';
import PhotoSelectHooks from 'src/components/photo/photo-list/PhotoSelectHooks';
import { useUrlParams } from 'src/hooks/url-params';
import { HeaderButton, HeaderMoreButton, HeaderProps } from 'src/model/component';
import { FriendPreview } from 'src/model/friend';
import { Album } from 'src/model/photo';
import router from 'src/pages/router';
import { dispatch } from 'src/redux';
import { GlobalActions } from 'src/redux/global';
import { useContextSetter } from 'src/utils/context';

export interface AlbumDetailPageParams {
  albumId: string;
}

function usePageParams(): AlbumDetailPageParams {
  const [albumId] = useUrlParams('albumId');
  return { albumId };
}

function useAlbumDelete() {
  const { albumId } = usePageParams();

  return async () => {
    if (!window.confirm(t('photo.album-delete-confirm'))) {
      return;
    }

    dispatch(GlobalActions.update({ loading: true }));

    await albumDeleteApi({ albumId });

    dispatch(GlobalActions.update({ loading: false }));

    router.navigate(-1);
  };
}

function useRenameAlbum() {
  const { albumId } = usePageParams();

  return async (name: string) => {
    dispatch(GlobalActions.update({ loading: true }));

    await albumUpdateApi({ albumId, name });

    dispatch(GlobalActions.update({ loading: false }));
  };
}

function useUpdateAlbumOwner() {
  const { albumId } = usePageParams();

  return async (friends: FriendPreview[]) => {
    dispatch(GlobalActions.update({ loading: true }));

    const friendIds = friends.map((v) => v.id);
    await albumOwnerUpdateApi({ albumId, friendIds });

    dispatch(GlobalActions.update({ loading: false }));
  };
}

function useDeletePhoto() {
  const { albumId } = usePageParams();
  const setPageState = useContextSetter(AlbumDetailContext);
  const [{ selects }] = useContext(PhotoSelectContext);
  const clear = PhotoSelectHooks.useClear();

  return async () => {
    const cnt = selects.length;
    if (!window.confirm(t('photo.msg.photo-delete-from-album-confirm', { cnt }))) {
      return;
    }

    const photoIds = selects.map((v) => v.id);

    dispatch(GlobalActions.update({ loading: true }));

    await albumPhotoDeleteApi({ albumId, photoIds });

    dispatch(GlobalActions.update({ loading: false }));

    setPageState({ selectMode: undefined });
    clear();
  };
}

function useRegisterThumbnail() {
  const { albumId } = usePageParams();
  const setPageState = useContextSetter(AlbumDetailContext);
  const [{ selects }] = useContext(PhotoSelectContext);
  const clear = PhotoSelectHooks.useClear();

  return async () => {
    const thumbnailId = selects[0].id;

    dispatch(GlobalActions.update({ loading: true }));

    await albumUpdateApi({ albumId, thumbnailId });

    dispatch(GlobalActions.update({ loading: false }));

    setPageState({ selectMode: undefined });
    clear();
  };
}

function useDownlaod() {
  const { albumId } = usePageParams();
  const setPageState = useContextSetter(AlbumDetailContext);
  const [{ selects }] = useContext(PhotoSelectContext);
  const clear = PhotoSelectHooks.useClear();

  return async () => {
    const photoIds = selects.map((v) => v.id);

    dispatch(GlobalActions.update({ loading: true }));

    await photoDownloadApi({ photoIds, albumId });

    dispatch(GlobalActions.update({ loading: false }));

    setPageState({ selectMode: undefined });
    clear();

    router.navigate(PhotoRoutes.downloadRoute);
  };
}

function useSelectComplete() {
  const [{ selectMode }] = useContext(AlbumDetailContext);

  const deletePhoto = useDeletePhoto();
  const registerThumbnail = useRegisterThumbnail();
  const download = useDownlaod();

  switch (selectMode) {
    case 'delete':
      return deletePhoto;
    case 'thumbnail':
      return registerThumbnail;
    case 'download':
      return download;
    default:
      return undefined;
  }
}

function useHeaderProps(album?: Album): HeaderProps {
  const setPageState = useContextSetter(AlbumDetailContext);
  const [{ selectMode }, setSelectState] = useContext(PhotoSelectContext);

  const deleteAlbum = useAlbumDelete();
  const onComplete = useSelectComplete();
  const headerPropsOnSelect = PhotoSelectHeaderHooks.useHeaderProps(onComplete);

  if (selectMode) {
    return headerPropsOnSelect;
  }

  const title = album?.name ?? '';
  const albumId = album?.id ?? '';

  const btns: HeaderButton[] = [
    {
      icon: 'fas fa-upload',
      name: t('photo.page.album-detail.upload'),
      onClick: () => router.navigate(PhotoRoutes.albumUpload(albumId)),
    },
  ];

  const menus: HeaderMoreButton[] = [
    {
      icon: 'fas fa-search',
      name: t('search'),
      onClick: () => setPageState({ showSearchModal: true }),
    },
    {
      icon: 'fas fa-minus',
      name: t('PhotoListView.delete-from-album'),
      onClick: () => {
        setPageState({ selectMode: 'delete' });
        setSelectState({ selectMode: true });
      },
    },
    {
      icon: 'fas fa-image',
      name: t('photo.register-thumbnail'),
      onClick: () => {
        setPageState({ selectMode: 'thumbnail' });
        setSelectState({ selectMode: true });
      },
    },
    {
      icon: 'fas fa-download',
      name: t('download'),
      onClick: () => {
        setPageState({ selectMode: 'download' });
        setSelectState({ selectMode: true });
      },
    },
    {
      icon: 'fas fa-sort-alpha-down',
      name: t('photo.term.sort-photos'),
      onClick: () => alert('not yet supported!'),
    },
    {
      icon: 'fas fa-share-alt',
      name: t('photo.term.album-share-setting'),
      onClick: () => {
        setPageState({ showFriendModal: true });
      },
    },
    {
      icon: 'fas fa-edit',
      name: t('photo.term.rename-album'),
      onClick: () => {
        setPageState({ showRenameModal: true });
      },
    },
    {
      icon: 'fas fa-trash',
      name: t('AlbumDetailPage.delete-album'),
      onClick: deleteAlbum,
    },
  ];

  return {
    back: true,
    title,
    btns,
    menus,
  };
}

const AlbumDetailHooks = {
  usePageParams,
  useAlbumDelete,
  useRenameAlbum,
  useUpdateAlbumOwner,
  useHeaderProps,
};

export default AlbumDetailHooks;
