import { Button, ButtonGroup } from 'react-bootstrap';

interface Props<T> {
  data: T[];
  labelSelector: (v: T) => string;
  onChange: (v: T) => void;
  value?: T;
}

export default function CommonRadioButton<T>(props: Props<T>) {
  const { data, labelSelector, onChange, value } = props;

  const elements = data.map((v, i) => {
    const key = `CommonRadioButton_${i}`;
    const label = labelSelector(v);
    const checked = v === value;
    const onClick = () => {
      onChange(v);
    };

    return (
      <Button key={key} variant={checked ? 'primary' : 'outline-primary'} onClick={onClick}>
        {label}
      </Button>
    );
  });

  return (
    <div>
      <ButtonGroup className="CommonRadioButton w-100">{elements}</ButtonGroup>
    </div>
  );
}
