import { generateApi } from '../generate-api';
import albumDetailApi from './album-detail';

interface AlbumOwnerUpdateParams {
  albumId: string;
  friendIds: string[];
}

const albumOwnerUpdateApi = generateApi<AlbumOwnerUpdateParams, any>({
  api: (params) => ({
    url: `/api/v1/albums/${params.albumId}/owners`,
    method: 'PUT',
    data: params,
  }),
  postHandle: (_, params) => {
    const { albumId } = params;
    albumDetailApi.invalidate({ albumId });
  },
});

export default albumOwnerUpdateApi;
