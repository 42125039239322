import { generateQuery } from '../generate-api';
import { PageData } from 'src/model/api';
import { PhotoDownload } from 'src/model/photo';

interface PhotoDownloadListParams {
  page?: number;
}

const photoDownloadListApi = generateQuery<PhotoDownloadListParams, PageData<PhotoDownload>>({
  api: (params) => ({
    url: '/api/v1/downloads',
    method: 'GET',
    params,
  }),
  key: 'photoDownloadListApi',
});

export default photoDownloadListApi;
