import GeneticCreatureItem, { GeneticCreatureHead } from './GeneticCreatureItem';
import { GeneticCreature } from 'src/model/genetic';

interface Props {
  creatures: GeneticCreature[];
}

export default function GeneticCreatureList(props: Props) {
  const { creatures } = props;

  const element = creatures.map((v, i) => <GeneticCreatureItem key={v.id} creature={v} i={i} />);

  return (
    <div className="GeneticCreatureList">
      <GeneticCreatureHead />
      {element}
    </div>
  );
}
