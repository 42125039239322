import { t } from 'i18next';
import { encodeURI } from 'js-base64';

import PhotoRoutes from '../../PhotoRoutes';
import PhotoListHooks from '../PhotoListHooks';
import { PhotoSearchParams } from 'src/api/photo/photo-search';
import CommonSearchFilter from 'src/components/common/search/CommonSearchFilter';
import router from 'src/pages/router';

import './PhotoSearchStatus.scss';

export default function PhotoSearchStatus() {
  const searchParams = PhotoListHooks.useSearchParams();
  const pageParams = PhotoListHooks.usePageParams();
  const { dateRange, orphan, name } = searchParams;

  const generateOnDelete = (updater: (params: PhotoSearchParams) => PhotoSearchParams) => () => {
    const newPageParams = {
      ...pageParams,
      search: encodeURI(JSON.stringify(updater(searchParams))),
    };

    router.navigate(PhotoRoutes.photos(newPageParams));
  };

  const elements: JSX.Element[] = [];

  if (dateRange) {
    const onDelete = generateOnDelete((v) => ({ ...v, dateRange: undefined }));

    elements.push(
      <CommonSearchFilter key="dateRange" onDelete={onDelete}>
        {dateRange.start} ~ {dateRange.end}
      </CommonSearchFilter>,
    );
  }

  if (orphan) {
    const onDelete = generateOnDelete((v) => ({ ...v, orphan: undefined }));

    elements.push(
      <CommonSearchFilter key="orphan" onDelete={onDelete}>
        {t('PhotoSearchForm.orphan')}
      </CommonSearchFilter>,
    );
  }

  if (name) {
    const onDelete = generateOnDelete((v) => ({ ...v, name: undefined }));

    elements.push(
      <CommonSearchFilter key="name" onDelete={onDelete}>
        {t('photo.filter.name', { name })}
      </CommonSearchFilter>,
    );
  }

  if (elements.length === 0) {
    return <></>;
  }

  return (
    <div className="PhotoSearchStatus mb-2">
      <div>{t('photo.term.search-filter')}</div>
      {elements}
    </div>
  );
}
