import { generateApi } from '../generate-api';
import photoDetailApi from './photo-detail';
import photoSearchApi from './photo-search';
import { ChronoUnit } from 'src/model/api';

export interface PhotoUpdateDateBulkParams {
  photoIds: string[];
  amount: number;
  unit: ChronoUnit;
}

const photoUpdateDateBulkApi = generateApi<PhotoUpdateDateBulkParams, any>({
  api: (data) => ({
    url: `/api/v1/_bulk/photos/update/date`,
    method: 'POST',
    data,
  }),
  postHandle: () => {
    photoDetailApi.invalidate();
    photoSearchApi.invalidate();
  },
});

export default photoUpdateDateBulkApi;
