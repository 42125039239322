import DiaryPreviewItem from './DiaryPreviewItem';
import { DiaryPreview } from 'src/model/diary';

interface Props {
  diaries: DiaryPreview[];
  query?: string;
}

export default function DiaryPreviewList(props: Props) {
  const { diaries, query } = props;

  const elements = diaries.map((v) => <DiaryPreviewItem key={v.date} diary={v} query={query} />);

  return <div className="DiaryPreviewList">{elements}</div>;
}
