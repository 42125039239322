import GeneticCombinationItem, { GeneticCombinationHead } from './GeneticCombinationItem';
import { GeneticCombination } from 'src/model/genetic';
import { useBreakpointMobile } from 'src/utils/breakpoint';

interface Props {
  combinations?: GeneticCombination[];
  hideGender?: boolean;
}

export default function GeneticCombinationList(props: Props) {
  const { combinations, hideGender } = props;
  const isMobile = useBreakpointMobile();

  if (!combinations) {
    return <></>;
  }

  const elements = combinations.map((v, i) => (
    <GeneticCombinationItem key={`${i}${v.id}`} i={i} combination={v} hideGender={hideGender} />
  ));

  return (
    <div className="GeneticCombinationList text-center">
      {isMobile || <GeneticCombinationHead hideGender={hideGender} />}
      {elements}
    </div>
  );
}
