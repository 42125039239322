import { generateApi } from '../generate-api';
import geneticCreatureSearchApi from './genetic-creature-search';
import geneticCreatureDetailApi from 'src/api/genetic/genetic-creature-detail';
import { GeneticCreatureFormState } from 'src/components/genetic/form/GeneticCreatureForm';
import { GeneticCreature } from 'src/model/genetic';

type GeneticCreatureCreateParams = GeneticCreatureFormState;

const geneticCreatureCreateApi = generateApi<GeneticCreatureCreateParams, GeneticCreature>({
  api: (params) => ({
    url: `/api/v1/creatures`,
    method: 'POST',
    data: params,
  }),
  postHandle: (result) => {
    const creatureId = result.id;
    geneticCreatureSearchApi.invalidate();
    geneticCreatureDetailApi.setCache({ creatureId }, result);
  },
});

export default geneticCreatureCreateApi;
