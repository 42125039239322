import cs from 'classnames';
import { PropsWithChildren } from 'react';

import './CommonDescription.scss';

interface Props {
  label: string;
  value?: string | number;
  onEdit?: () => void;
}

function CommonDescription(props: PropsWithChildren<Props>) {
  const { label, value, children, onEdit } = props;

  const onClick = () => {
    onEdit?.();
  };

  return (
    <div className={cs('CommonDescription', { onEdit: !!onEdit })} onClick={onClick}>
      <label>
        <span>{label}</span>
        {onEdit && <i className="fas fa-pen" />}
      </label>
      {children ?? <div className="value">{value}</div>}
    </div>
  );
}

function Group(props: PropsWithChildren<{}>) {
  return <div className="CommonDescriptionGroup">{props.children}</div>;
}

export default Object.assign(CommonDescription, { Group });
