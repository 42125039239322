import { generateQuery } from '../generate-api';
import { GeneticTypeFormState } from 'src/components/genetic/form/GeneticTypeForm';
import { GeneticCombination } from 'src/model/genetic';

export type GeneticCombinationParams = GeneticTypeFormState;

export interface GeneticCombinationResult {
  result: GeneticCombination[];
}

const geneticReproduceApi = generateQuery<GeneticCombinationParams, GeneticCombinationResult>({
  api: (params) => ({
    url: `/api/v1/reproduce`,
    method: 'POST',
    data: params,
  }),
  key: 'geneticReproduceApi',
});

export default geneticReproduceApi;
