import { t } from 'i18next';
import { Button, Form } from 'react-bootstrap';
import { useController, useForm } from 'react-hook-form';

import GeneticTypeRadioButton from 'src/components/genetic/form/GeneticTypeRadioButton';

interface Props {
  onSubmit: (state: GeneticTypeFormState) => void;
}

export interface GeneticTypeFormState {
  type1: number;
  type2: number;
  type3: number;
  type4: number;
}

export default function GeneticTypeForm(props: Props) {
  const defaultValues = useDefaultValues();
  const { handleSubmit, control } = useForm<GeneticTypeFormState>({ defaultValues });
  const type1Field = useController({ name: 'type1', control }).field;
  const type2Field = useController({ name: 'type2', control }).field;
  const type3Field = useController({ name: 'type3', control }).field;
  const type4Field = useController({ name: 'type4', control }).field;

  return (
    <Form className="GeneticTypeForm d-grid gap-3" onSubmit={handleSubmit(props.onSubmit)}>
      <Form.Group>
        <Form.Label>{t('genetic.type1')}</Form.Label>
        <GeneticTypeRadioButton onChange={type1Field.onChange} value={type1Field.value} />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('genetic.type2')}</Form.Label>
        <GeneticTypeRadioButton onChange={type2Field.onChange} value={type2Field.value} />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('genetic.type3')}</Form.Label>
        <GeneticTypeRadioButton onChange={type3Field.onChange} value={type3Field.value} />
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('genetic.type4')}</Form.Label>
        <GeneticTypeRadioButton onChange={type4Field.onChange} value={type4Field.value} />
      </Form.Group>
      <Button variant="primary" type="submit">
        {t('complete')}
      </Button>
    </Form>
  );
}

function useDefaultValues(): GeneticTypeFormState {
  return {
    type1: 0,
    type2: 0,
    type3: 0,
    type4: 0,
  };
}
