import { t } from 'i18next';
import { useState } from 'react';

import GeneticReproduceResultView from './component/GeneticReproduceResultView';
import CommonLayout from 'src/components/common/layout/CommonLayout';
import GeneticTypeForm, { GeneticTypeFormState } from 'src/components/genetic/form/GeneticTypeForm';

export default function GeneticReproducePage() {
  const [formState, setFormState] = useState<GeneticTypeFormState>();

  return (
    <CommonLayout className="GeneticReproducePage" title={t('GeneticNavigation.reproduce')}>
      <GeneticTypeForm onSubmit={setFormState} />
      {formState && <GeneticReproduceResultView formState={formState} />}
    </CommonLayout>
  );
}
