import geneticChildrenApi from 'src/api/genetic/genetic-children';
import { Loading } from 'src/components/common/LoadingSuspense';
import GeneticCombinationList from 'src/components/genetic/GeneticCombinationList';
import { GeneticParentFormState } from 'src/components/genetic/form/GeneticParentForm';

interface Props {
  formState: GeneticParentFormState;
}

export default function GeneticChildrenResultView(props: Props) {
  const { formState } = props;
  const params = {
    fatherId: formState.father!!.id,
    motherId: formState.mother!!.id,
  };
  const { data } = geneticChildrenApi.useApiResult(params);

  if (!data) {
    return <Loading />;
  }

  return (
    <div className="GeneticChildrenResultView mt-3">
      <GeneticCombinationList combinations={data.result} hideGender={true} />
    </div>
  );
}
