import Chart, { ChartConfiguration } from 'chart.js/auto';
import { useEffect, useRef } from 'react';

import { DiaryStatsLengthCnt } from 'src/api/diary/diary-stats';

interface Props {
  cntList: DiaryStatsLengthCnt[];
}

export default function DiaryStatsLengthCntChart(props: Props) {
  const { cntList } = props;

  useInit(cntList);

  return <canvas className="DiaryStatsLengthCntChart"></canvas>;
}

function init(cntList: DiaryStatsLengthCnt[]): Chart {
  const element: HTMLCanvasElement = document.querySelector('.DiaryStatsLengthCntChart') as HTMLCanvasElement;

  const config: ChartConfiguration = {
    type: 'bar',
    data: {
      labels: cntList.map((row) => row.range),
      datasets: [
        {
          data: cntList.map((row) => row.cnt),
          backgroundColor: '#0d6efd',
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  };

  return new Chart(element, config);
}

function useInit(cntList: DiaryStatsLengthCnt[]) {
  const ref = useRef<Chart>();

  useEffect(() => {
    if (ref.current) {
      return;
    }

    ref.current = init(cntList);

    return () => {
      ref.current?.destroy();
      ref.current = undefined;
    };
  }, [cntList]);

  if (ref.current) {
    return ref.current;
  }
}
