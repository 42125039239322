import { t } from 'i18next';
import { useContext } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';

import { AlbumDetailContext } from '../AlbumDetailContext';
import AlbumDetailHooks from '../AlbumDetailHooks';

interface FormState {
  name: string;
}

export default function AlbumRenameModal(defaultValues: FormState) {
  const [{ showRenameModal }, setState] = useContext(AlbumDetailContext);
  const rename = AlbumDetailHooks.useRenameAlbum();

  const { register, handleSubmit, reset } = useForm<FormState>({ defaultValues });

  const onHide = () => {
    reset();
    setState({ showRenameModal: false });
  };

  const onSubmit: SubmitHandler<FormState> = (state: FormState) => {
    onHide();
    rename(state.name);
  };

  return (
    <Modal className="AlbumRenameModal" show={showRenameModal} onHide={onHide} centered>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('photo.term.rename-album')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{t('photo.term.album-name')}</Form.Label>
            <Form.Control {...register('name')} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">{t('edit')}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
