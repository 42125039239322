import { t } from 'i18next';

import { DiarySearchParams } from 'src/api/diary/diary-search';
import { useOptionalUrlParams } from 'src/hooks/url-params';
import { HeaderButton, HeaderProps } from 'src/model/component';
import DiaryRoutes from 'src/pages/diary/DiaryRoutes';
import { DiaryListContext } from 'src/pages/diary/list/DiaryListContext';
import router from 'src/pages/router';
import { useContextSetter } from 'src/utils/context';

export interface DiaryListPageParams {
  query?: string;
}

function usePageParams(): DiaryListPageParams {
  const [query] = useOptionalUrlParams('query');
  return { query };
}

function useSearchParams(): DiarySearchParams {
  const { query } = usePageParams();

  return {
    query: query ? query : undefined,
  };
}

function useHeaderProps(): HeaderProps {
  const setState = useContextSetter(DiaryListContext);

  const btns: HeaderButton[] = [
    {
      icon: 'fas fa-search',
      name: t('search'),
      onClick: () => setState({ showSearch: true }),
    },
    {
      icon: 'fas fa-plus',
      name: t('add'),
      onClick: () => router.navigate(DiaryRoutes.createRoute),
    },
  ];

  return {
    title: t('DiaryListPage.title'),
    btns,
  };
}

const DiaryListHooks = {
  usePageParams,
  useSearchParams,
  useHeaderProps,
};

export default DiaryListHooks;
