import { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import { useRandomString } from 'src/utils/hooks';

interface Props {
  file: File;
}

export default function CommonFileThumbnail(props: Props) {
  const { file } = props;
  const ref = useRef<HTMLCanvasElement>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const nonce = useRandomString();

  useEffect(() => {
    const canvas = ref.current;
    if (canvas) {
      generateThumbnail(canvas, file, nonce, () => {
        setLoading(false);
      });
    }
  }, [file, nonce]);

  return (
    <div className="CommonFileThumbnail ratio">
      <canvas ref={ref} />
      <div className="flex_center" style={{ display: loading ? undefined : 'none' }} data-nonce={nonce}>
        <Spinner animation="border"></Spinner>
      </div>
    </div>
  );
}

const SIZE = 512;

function generateThumbnail(canvas: HTMLCanvasElement, file: File, nonce: string, callback: () => void) {
  canvas.width = SIZE;
  canvas.height = SIZE;
  const context = canvas.getContext('2d');
  if (!context) {
    return;
  }

  const url = URL.createObjectURL(file);

  const image = new Image();
  image.onload = () => {
    const sleep = Math.random() * 1000;
    setTimeout(() => {
      draw(context, image);
      URL.revokeObjectURL(url);
      const element = document.querySelector(`[data-nonce="${nonce}"]`) as HTMLDivElement;
      element.style.display = 'none';
      callback();
    }, sleep);
  };
  image.src = url;
}

function draw(context: CanvasRenderingContext2D, image: HTMLImageElement) {
  const hRatio = SIZE / image.width;
  const vRatio = SIZE / image.height;
  const ratio = Math.max(hRatio, vRatio);

  const x = (SIZE - image.width * ratio) / 2;
  const y = (SIZE - image.height * ratio) / 2;

  context.clearRect(0, 0, SIZE, SIZE);
  context.drawImage(image, 0, 0, image.width, image.height, x, y, image.width * ratio, image.height * ratio);
}
