import { t } from 'i18next';

import geneticCombinationApi from 'src/api/genetic/genetic-combination';
import { Loading2 } from 'src/components/common/LoadingSuspense';
import CommonLayout from 'src/components/common/layout/CommonLayout';
import GeneticCombinationList from 'src/components/genetic/GeneticCombinationList';

export default function GeneticCombinationPage() {
  const { data, isLoading } = geneticCombinationApi.useApiResult({}, { suspense: false });

  return (
    <CommonLayout className="GeneticCombinationPage" title={t('GeneticNavigation.combination')}>
      <Loading2 isLoading={isLoading}>
        <GeneticCombinationList combinations={data?.result} />
      </Loading2>
    </CommonLayout>
  );
}
