import { t } from 'i18next';
import { Navigate } from 'react-router-dom';

import GeneticRoutes from '../GeneticRoutes';
import GeneticCreatureDetailHooks from './GeneticCreatureDetailHooks';
import geneticCreatureDetailApi from 'src/api/genetic/genetic-creature-detail';
import { Loading } from 'src/components/common/LoadingSuspense';
import CommonLayout from 'src/components/common/layout/CommonLayout';
import { HeaderButton } from 'src/model/component';
import CommonRoutes from 'src/pages/common/CommonRoutes';
import GeneticCreatureDetailView from 'src/pages/genetic/detail/components/GeneticCreatureDetailView';
import router from 'src/pages/router';

export default function GeneticCreatureDetailPage() {
  const { creatureId } = GeneticCreatureDetailHooks.usePageParams();
  const { data, isLoading } = geneticCreatureDetailApi.useApiResult({ creatureId });

  // functions
  const remove = GeneticCreatureDetailHooks.useDelete();

  // elements
  const headerBtns: HeaderButton[] = [
    {
      icon: 'fas fa-edit',
      name: t('edit'),
      onClick: () => router.navigate(GeneticRoutes.update({ creatureId })),
    },
    {
      icon: 'fas fa-trash-alt',
      name: t('delete'),
      onClick: () => (data ? remove(data) : undefined),
    },
  ];

  let content = <></>;
  if (isLoading) {
    content = <Loading />;
  } else if (data) {
    content = <GeneticCreatureDetailView creature={data} />;
  } else {
    content = <Navigate to={CommonRoutes.notFound} replace />;
  }

  return (
    <CommonLayout
      className="GeneticCreatureDetailPage"
      title={t('GeneticCreatureDetailPage.title', { name: data?.name ?? '' })}
      btns={headerBtns}
      back
    >
      {content}
    </CommonLayout>
  );
}
