import GeneticCombinationList from '../../../../components/genetic/GeneticCombinationList';
import geneticReproduceApi from 'src/api/genetic/genetic-reproduce';
import { Loading } from 'src/components/common/LoadingSuspense';
import { GeneticTypeFormState } from 'src/components/genetic/form/GeneticTypeForm';

interface Props {
  formState: GeneticTypeFormState;
}

export default function GeneticReproduceResultView(props: Props) {
  const { formState } = props;
  const { data } = geneticReproduceApi.useApiResult(formState);

  if (!data) {
    return <Loading />;
  }

  return (
    <div className="GeneticReproduceResultView mt-3">
      <GeneticCombinationList combinations={data.result} />
    </div>
  );
}
