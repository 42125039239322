import { t } from 'i18next';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import GeneticCreatureSelect from './GeneticCreatureSelect';
import useCustomController from 'src/hooks/common/useCustomController';
import { GeneticCreature } from 'src/model/genetic';

interface Props {
  creatures: GeneticCreature[];
  onSubmit: (state: GeneticParentFormState) => void;
}

export interface GeneticParentFormState {
  father?: GeneticCreature;
  mother?: GeneticCreature;
}

export default function GeneticParentForm(props: Props) {
  const defaultValues = useDefaultValues();
  const { handleSubmit, control } = useForm<GeneticParentFormState>({ defaultValues });
  const fatherField = useCustomController({ name: 'father', control, rules: { required: true } });
  const motherField = useCustomController({ name: 'mother', control, rules: { required: true } });
  const fathers = props.creatures.filter((v) => v.isMale);
  const mothers = props.creatures.filter((v) => !v.isMale);

  return (
    <Form className="GeneticParentForm d-grid gap-3" onSubmit={handleSubmit(props.onSubmit)}>
      <Form.Group>
        <Form.Label>{t('male')}</Form.Label>
        <GeneticCreatureSelect data={fathers} {...fatherField} />
        <Form.Control.Feedback type="invalid">{t('common.form.feedback.required')}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('female')}</Form.Label>
        <GeneticCreatureSelect data={mothers} {...motherField} />
        <Form.Control.Feedback type="invalid">{t('common.form.feedback.required')}</Form.Control.Feedback>
      </Form.Group>
      <Button variant="primary" type="submit">
        {t('complete')}
      </Button>
    </Form>
  );
}

function useDefaultValues(): GeneticParentFormState {
  return {};
}
