import { t } from 'i18next';

import GeneticRoutes from '../../GeneticRoutes';
import { GeneticCreature } from 'src/model/genetic';
import router from 'src/pages/router';

import './GeneticCreatureItem.scss';

interface Props {
  i: number;
  creature: GeneticCreature;
}

export default function GeneticCreatureItem(props: Props) {
  const { i, creature } = props;
  const { name, birthday, punch, isMale, type1, type2, type3, type4 } = creature;

  const onClick = () => {
    router.navigate(GeneticRoutes.detail({ creatureId: creature.id }));
  };

  return (
    <div className="GeneticCreatureItem hyunsub_border gray_bg_hover" onClick={onClick}>
      <div className="index">{i + 1}</div>
      <div className="name">{name}</div>
      <div className="birthday">{birthday}</div>
      <div className="punch">{punch}</div>
      <div className="isMale">{isMale ? 'M' : 'F'}</div>
      <div className="type1">{printType1(type1)}</div>
      <div className="type2">{printType2(type2)}</div>
      <div className="type3">{printType3(type3)}</div>
      <div className="type4">{printType4(type4)}</div>
    </div>
  );
}

export function GeneticCreatureHead() {
  return (
    <div className="GeneticCreatureItem GeneticCreatureHead">
      <div className="index">#</div>
      <div className="name">{t('GeneticCreature.name')}</div>
      <div className="birthday">{t('GeneticCreature.birthday')}</div>
      <div className="punch">{t('GeneticCreature.punch')}</div>
      <div className="isMale">{t('GeneticCreature.isMale')}</div>
      <div className="type1">{t('genetic.type1')}</div>
      <div className="type2">{t('genetic.type2')}</div>
      <div className="type3">{t('genetic.type3')}</div>
      <div className="type4">{t('genetic.type4')}</div>
    </div>
  );
}

function generateTypePrinter(label: string) {
  return function printType(v: number): string {
    switch (v) {
      case 0:
        return '+/+';
      case 1:
        return `+/${label}`;
      case 2:
        return `${label}/${label}`;
      default:
        return '';
    }
  };
}

const printType1 = generateTypePrinter('Tg');
const printType2 = generateTypePrinter('fl');
const printType3 = generateTypePrinter('Cre');
const printType4 = generateTypePrinter('Ai14');
