import { generateQuery } from '../generate-api';
import { GeneticCombination } from 'src/model/genetic';

export interface GeneticChildrenParams {
  fatherId: string;
  motherId: string;
}

export interface GeneticChildrenResult {
  result: GeneticCombination[];
}

const geneticChildrenApi = generateQuery<GeneticChildrenParams, GeneticChildrenResult>({
  api: (params) => ({
    url: `/api/v1/children`,
    method: 'POST',
    data: params,
  }),
  key: 'geneticChildrenApi',
});

export default geneticChildrenApi;
