import { t } from 'i18next';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import PhotoListHooks from '../PhotoListHooks';
import { PhotoSearchParams } from 'src/api/photo/photo-search';

export default function PhotoSearchForm() {
  const defaultValues = useDefaultValues();
  const { register, handleSubmit } = useForm<PhotoSearchParams>({ defaultValues });
  const search = PhotoListHooks.useSearch();

  return (
    <Form className="PhotoSearchForm d-grid gap-3" onSubmit={handleSubmit(search)}>
      <Form.Group>
        <Form.Label>{t('PhotoSearchForm.date-range')}</Form.Label>
        <InputGroup>
          <Form.Control type="date" {...register('dateRange.start')} />
          <InputGroup.Text>~</InputGroup.Text>
          <Form.Control type="date" {...register('dateRange.end')} />
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <Form.Label>{t('photo.term.photo-name')}</Form.Label>
        <Form.Control {...register('name')} />
      </Form.Group>
      <Form.Group>
        <Form.Check id="orphan" label={t('PhotoSearchForm.orphan')} {...register('orphan')} />
      </Form.Group>
      <Button variant="primary" type="submit">
        {t('search')}
      </Button>
    </Form>
  );
}

function useDefaultValues(): PhotoSearchParams {
  return PhotoListHooks.useSearchParams();
}
