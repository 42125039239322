import { t } from 'i18next';
import { floor } from 'lodash';

import DiaryStatsLengthCntChart from './components/DiaryStatsLengthCntChart';
import DiaryStatsMaxItem from './components/DiaryStatsMaxItem';
import diaryStatsApi, { DiaryStatsPeriod, DiaryStatsResult } from 'src/api/diary/diary-stats';
import { Loading } from 'src/components/common/LoadingSuspense';
import CommonDescription from 'src/components/common/description/CommonDescription';
import CommonLayout from 'src/components/common/layout/CommonLayout';
import { numberWithComma } from 'src/utils';

export default function DiaryStatsPage() {
  const { data } = diaryStatsApi.useApiResult({}, { suspense: false });

  const content = data ? DiaryStatsPageContent(data) : <Loading />;

  return (
    <CommonLayout className="DiaryStatsPage" title={t('DiaryNavigation.stats')}>
      {content}
    </CommonLayout>
  );
}

function DiaryStatsPageContent(data: DiaryStatsResult): JSX.Element {
  const { cnt, date, length } = data;
  const { period } = date;

  const lengthMaxList = length.maxList.map((v) => <DiaryStatsMaxItem key={v.date} data={v} />);

  return (
    <div>
      <h4>{t('DiaryStatsPage.total', { cnt: numberWithComma(cnt) })}</h4>
      <div className="hyunsub_border py-3">
        <h4>{t('DiaryStatsPage.date')}</h4>
        <CommonDescription.Group>
          <CommonDescription label={t('DiaryStatsPage.date.min')} value={date.min} />
          <CommonDescription label={t('DiaryStatsPage.date.max')} value={date.max} />
          <CommonDescription label={t('DiaryStatsPage.date.total')} value={periodText(period)} />
          <CommonDescription
            label={t('DiaryStatsPage.date.avg.label')}
            value={t('DiaryStatsPage.date.avg.value', { cnt: floor(cnt / period.totalDays, 3) })}
          />
        </CommonDescription.Group>
      </div>
      <div className="hyunsub_border py-3">
        <h4>{t('DiaryStatsPage.length')}</h4>
        <div className="d-grid gap-2">
          <CommonDescription.Group>
            <CommonDescription
              label={t('DiaryStatsPage.length.avg')}
              value={t('letters', { cnt: numberWithComma(floor(length.average, 2)) })}
            />
            <CommonDescription
              label={t('DiaryStatsPage.length.total')}
              value={t('letters', { cnt: numberWithComma(length.total) })}
            />
          </CommonDescription.Group>
          <CommonDescription label={t('DiaryStatsPage.length.top5')}>
            <ul className="mb-0 value">{lengthMaxList}</ul>
          </CommonDescription>
          <CommonDescription label={t('DiaryStatsPage.length.distribution')}>
            <DiaryStatsLengthCntChart cntList={length.cntList} />
          </CommonDescription>
        </div>
      </div>
    </div>
  );
}

function periodText(period: DiaryStatsPeriod): string {
  const { years, months, days, totalDays } = period;
  const list: string[] = [];

  if (years) {
    list.push(t('period.year', { years }));
  }

  if (months) {
    list.push(t('period.month', { months }));
  }

  list.push(t('period.day', { days }));

  return `${list.join(' ')} (${t('period.day', { days: numberWithComma(totalDays) })})`;
}
