import { Suspense } from 'react';
import { Outlet, RouteObject, ScrollRestoration, createBrowserRouter } from 'react-router-dom';

import { ApparelRouteObjects } from './apparel/ApparelIndex';
import { AppsRouteObjects } from './apps/AppsIndex';
import { AuthRouteObjects } from './auth/AuthIndex';
import { ComicRouteObjects } from './comic/ComicIndex';
import NotFoundPage from './common/NotFoundPage';
import RouteErrorBoundary from './common/RouteErrorBoundary';
import { DiaryRouteObjects } from './diary/DiaryIndex';
import { DriveRouteObjects } from './drive/DriveIndex';
import { DutchRouteObjects } from './dutch/DutchIndex';
import { FriendRouteObjects } from './friend/FriendIndex';
import { GeneticRouteObjects } from './genetic/GeneticIndex';
import { PhotoRouteObjects } from './photo/PhotoIndex';
import { VestigeRouteObjects } from './vestige/VestigeIndex';
import { VideoRouteObjects } from './video/VideoIndex';
import LoadingPage from 'src/pages/common/LoadingPage';

function pickRoutes(): RouteObject[] {
  const host = window.location.hostname;

  if (host.endsWith('auth.hyunsub.kim')) {
    return AuthRouteObjects;
  }

  if (host.endsWith('video.hyunsub.kim')) {
    return VideoRouteObjects;
  }

  if (host.endsWith('photo.hyunsub.kim')) {
    return PhotoRouteObjects;
  }

  if (host.endsWith('apparel.hyunsub.kim')) {
    return ApparelRouteObjects;
  }

  if (host.endsWith('drive.hyunsub.kim')) {
    return DriveRouteObjects;
  }

  if (host.endsWith('comic.hyunsub.kim')) {
    return ComicRouteObjects;
  }

  if (host.endsWith('vestige.hyunsub.kim')) {
    return VestigeRouteObjects;
  }

  if (host.endsWith('apps.hyunsub.kim')) {
    return AppsRouteObjects;
  }

  if (host.endsWith('diary.hyunsub.kim')) {
    return DiaryRouteObjects;
  }

  if (host.endsWith('friend.hyunsub.kim')) {
    return FriendRouteObjects;
  }

  if (host.endsWith('dutch.hyunsub.kim')) {
    return DutchRouteObjects;
  }

  if (host.endsWith('genetic.hyunsub.kim')) {
    return GeneticRouteObjects;
  }

  return [];
}

const router = createBrowserRouter([
  {
    errorElement: <RouteErrorBoundary />,
    element: (
      <>
        <Suspense fallback={<LoadingPage />}>
          <Outlet />
        </Suspense>
        <ScrollRestoration />
      </>
    ),
    children: [{ path: '*', element: <NotFoundPage /> }, ...pickRoutes()],
  },
]);

export default router;
