import { t } from 'i18next';
import { Navigate } from 'react-router-dom';

import GeneticCreatureUpdateHooks from './GeneticCreatureUpdateHooks';
import geneticCreatureDetailApi from 'src/api/genetic/genetic-creature-detail';
import { Loading } from 'src/components/common/LoadingSuspense';
import CommonLayout from 'src/components/common/layout/CommonLayout';
import GeneticCreatureForm from 'src/components/genetic/form/GeneticCreatureForm';
import CommonRoutes from 'src/pages/common/CommonRoutes';

export default function GeneticCreatureUpdatePage() {
  const { creatureId } = GeneticCreatureUpdateHooks.usePageParams();
  const { data, isLoading } = geneticCreatureDetailApi.useApiResult({ creatureId }, { suspense: false });
  const update = GeneticCreatureUpdateHooks.useUpdate();

  let content = <></>;
  if (isLoading) {
    content = <Loading />;
  } else if (data) {
    content = <GeneticCreatureForm onSubmit={update} creature={data} />;
  } else {
    content = <Navigate to={CommonRoutes.notFound} replace />;
  }

  return (
    <CommonLayout className="GeneticCreatureUpdatePage" title={t('CeneticCreatureUpdatePage.title')} back>
      {content}
    </CommonLayout>
  );
}
