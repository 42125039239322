import DiaryDetailHooks from 'src/pages/diary/detail/DiaryDetailHooks';

interface Props {
  content: string;
}

export default function DiaryDetailContent(props: Props) {
  const { content } = props;

  const { query } = DiaryDetailHooks.usePageParams();

  const lines = content.split('\n');

  const elements = lines.map((v, i) => {
    const body = query ? highlightQuery(v, query) : v;
    return <p key={i}>{body}</p>;
  });

  return <div className="DiaryDetailContent">{elements}</div>;
}

function highlightQuery(content: string, query: string): JSX.Element[] {
  const result: JSX.Element[] = [];

  const parts = content.split(query);
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];

    if (i !== 0) {
      result.push(
        <span key={`query_${i}`} className="query">
          {query}
        </span>,
      );
    }

    result.push(<span key={`content_${i}`}>{part}</span>);
  }

  return result;
}
