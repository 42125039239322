import { t } from 'i18next';
import { useContext } from 'react';

import PhotoRoutes from '../PhotoRoutes';
import { PhotoListContext, PhotoListProvider } from './PhotoListContext';
import PhotoListHooks from './PhotoListHooks';
import PhotoSearchModal from './components/PhotoSearchModal';
import { useFlattenPageData, useTotal } from 'src/api/generate-infinite-query';
import photoSearchApi from 'src/api/photo/photo-search';
import ListLoadingIndicator from 'src/components/common/ListLoadingIndicator';
import CommonLayout from 'src/components/common/layout/CommonLayout';
import PhotoUpdateDateBulkModal from 'src/components/photo/modal/PhotoUpdateDateBulkModal';
import PhotoListView from 'src/components/photo/photo-list/PhotoListView';
import { PhotoSelectProvider } from 'src/components/photo/photo-list/PhotoSelectContext';
import { useScrollBottom } from 'src/hooks/scroll-bottom';
import { PhotoPreview } from 'src/model/photo';
import AlbumPhotoRegisterSelectModal from 'src/pages/photo/photo-list/components/AlbumPhotoRegisterSelectModal';
import PhotoSearchStatus from 'src/pages/photo/photo-list/components/PhotoSearchStatus';
import { numberWithComma } from 'src/utils';

function PhotoListPage() {
  // hooks
  const { search } = PhotoListHooks.usePageParams();
  const searchParams = PhotoListHooks.useSearchParams();
  const { data, fetchNextPage, isFetching } = photoSearchApi.useInfiniteApi(searchParams);
  const photos = useFlattenPageData(data);
  const total = useTotal(data);
  const [state, setState] = useContext(PhotoListContext);
  const updateDateBulk = PhotoListHooks.useUpdateDateBulk();

  useScrollBottom(() => {
    if (!isFetching) {
      fetchNextPage();
    }
  });

  const headerProps = PhotoListHooks.useHeaderProps();

  const itemHref = (v: PhotoPreview) => PhotoRoutes.photoViewer({ photoId: v.id, search });

  return (
    <CommonLayout className="PhotoListPage" {...headerProps} noContainer>
      <PhotoSearchStatus />
      <div className="fs-4 mb-2">{t('AlbumInfoView.photo-num', { cnt: numberWithComma(total ?? 0) })}</div>
      <PhotoListView photos={photos} itemHref={itemHref} />
      <ListLoadingIndicator isFetching={isFetching} />
      <AlbumPhotoRegisterSelectModal />
      <PhotoUpdateDateBulkModal
        show={state.showDateUpdateModal}
        onHide={() => setState({ showDateUpdateModal: false })}
        callback={updateDateBulk}
      />
    </CommonLayout>
  );
}

export default function PhotoListIndex() {
  return (
    <PhotoSelectProvider>
      <PhotoListProvider>
        <PhotoListPage />
        <PhotoSearchModal />
      </PhotoListProvider>
    </PhotoSelectProvider>
  );
}
