import { generateApi } from '../generate-api';
import geneticCreatureDetailApi from './genetic-creature-detail';
import geneticCreatureSearchApi from './genetic-creature-search';
import { GeneticCreature } from 'src/model/genetic';

type GeneticCreatureDeleteParams = { creatureId: string };

const geneticCreatureDeleteApi = generateApi<GeneticCreatureDeleteParams, GeneticCreature>({
  api: (params) => ({
    url: `/api/v1/creatures/${params.creatureId}`,
    method: 'DELETE',
  }),
  postHandle: (_, params) => {
    const creatureId = params.creatureId;
    geneticCreatureSearchApi.invalidate();
    geneticCreatureDetailApi.setCache({ creatureId }, null);
  },
});

export default geneticCreatureDeleteApi;
