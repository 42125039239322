import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import GeneticChildrenPage from './children/GeneticChildrenPage';
import GeneticCombinationPage from './combination/GeneticCombinationPage';
import GeneticCreatureCreatePage from './create/GeneticCreatureCreatePage';
import GeneticCreatureDetailPage from './detail/GeneticCreatureDetailPage';
import GeneticReproducePage from './reproduce/GeneticReproducePage';
import GeneticCreatureUpdatePage from './update/GeneticCreatureUpdatePage';
import GeneticNavigation from 'src/components/genetic/GeneticNavigation';
import routes from 'src/pages/genetic/GeneticRoutes';
import GeneticCreatureListPage from 'src/pages/genetic/list/GeneticCreatureListPage';

export const GeneticRouteObjects: RouteObject[] = [
  {
    path: '/',
    element: (
      <>
        <GeneticNavigation />
        <Outlet />
      </>
    ),
    children: [
      { path: '/', element: <Navigate to={routes.listRoute} /> },
      { path: routes.listRoute, element: <GeneticCreatureListPage /> },
      { path: routes.createRoute, element: <GeneticCreatureCreatePage /> },
      { path: routes.detailRoute, element: <GeneticCreatureDetailPage /> },
      { path: routes.updateRoute, element: <GeneticCreatureUpdatePage /> },
      { path: routes.combinationRoute, element: <GeneticCombinationPage /> },
      { path: routes.reproduceRoute, element: <GeneticReproducePage /> },
      { path: routes.childrenRoute, element: <GeneticChildrenPage /> },
    ],
  },
];
