import { generateApi } from '../generate-api';
import geneticCreatureDetailApi from './genetic-creature-detail';
import geneticCreatureSearchApi from './genetic-creature-search';
import { GeneticCreatureFormState } from 'src/components/genetic/form/GeneticCreatureForm';
import { GeneticCreature } from 'src/model/genetic';

type GeneticCreatureUpdateParams = GeneticCreatureFormState & { creatureId: string };

const geneticCreatureUpdateApi = generateApi<GeneticCreatureUpdateParams, GeneticCreature>({
  api: (params) => ({
    url: `/api/v1/creatures/${params.creatureId}`,
    method: 'PUT',
    data: params,
  }),
  postHandle: (result) => {
    const creatureId = result.id;
    geneticCreatureSearchApi.invalidate();
    geneticCreatureDetailApi.setCache({ creatureId }, result);
  },
});

export default geneticCreatureUpdateApi;
