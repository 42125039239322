import { t } from 'i18next';
import { Button } from 'react-bootstrap';

import { useTokenPayload } from 'src/hooks/token';
import AppConstant from 'src/utils/constants';

import './MenuProfileView.scss';

export default function MenuProfileView() {
  const { idNo, username } = useTokenPayload();
  const profile = `https://file.hyunsub.kim/hyunsub/auth/${idNo}/profile.jpg`;

  const onClick = () => {
    window.location.href = AppConstant.PROFILE_HOME;
  };

  const imageElement = <img alt={username} className="profile" src={profile} />;

  return (
    <div className="MenuProfileView">
      {imageElement}
      <span className="username">{username}</span>
      <Button variant="secondary" size="sm" onClick={onClick}>
        {t('menu.profile')}
      </Button>
    </div>
  );
}
