import PhotoRoutes from '../PhotoRoutes';
import { useOptionalUrlParams } from 'src/hooks/url-params';
import router from 'src/pages/router';
import { downloadFile } from 'src/utils';

export interface PhotoDownloadPageParams {
  page?: number;
}

function usePageParams(): PhotoDownloadPageParams {
  const [_page] = useOptionalUrlParams('page');
  const page = _page ? parseInt(_page) : undefined;
  return { page };
}

function useSetPageParams() {
  const params = usePageParams();

  return (newParams: Partial<PhotoDownloadPageParams>) => {
    router.navigate(PhotoRoutes.download({ ...params, ...newParams }));
  };
}

function useDownload() {
  return (url: string) => {
    downloadFile(url, 'archive.zip');
  };
}

const PhotoDownloadHooks = {
  usePageParams,
  useSetPageParams,
  useDownload,
};

export default PhotoDownloadHooks;
