import PhotoRoutes from '../PhotoRoutes';
import { PhotoDetailContext } from './PhotoDetailContext';
import photoUpdateDateApi, { PhotoUpdateDateParams } from 'src/api/photo/photo-update-date';
import { useUrlParams } from 'src/hooks/url-params';
import router from 'src/pages/router';
import { dispatch } from 'src/redux';
import { GlobalActions } from 'src/redux/global';
import { useContextSetter } from 'src/utils/context';

export interface PhotoDetailPageParams {
  photoId: string;
}

function usePageParams(): PhotoDetailPageParams {
  const [photoId] = useUrlParams('photoId');
  return { photoId };
}

function useShowDateModal(show: boolean) {
  const setState = useContextSetter(PhotoDetailContext);

  return () => {
    setState({ showDateModal: show });
  };
}

function usePhotoUpdateDate() {
  const hide = useShowDateModal(false);

  return async (params: PhotoUpdateDateParams) => {
    dispatch(GlobalActions.update({ loading: true }));

    const result = await photoUpdateDateApi(params);

    hide();

    dispatch(GlobalActions.update({ loading: false }));

    router.navigate(PhotoRoutes.photoDetail({ photoId: result.id }), { replace: true });
  };
}

const PhotoDetailHooks = {
  usePageParams,
  useShowDateModal,
  usePhotoUpdateDate,
};

export default PhotoDetailHooks;
