import { forwardRef } from 'react';
import { Dropdown } from 'react-bootstrap';

import { HeaderMoreButton } from 'src/model/component';

interface ButtonProps {
  onClick: () => void;
}

const Toggle = forwardRef<HTMLElement, ButtonProps>(({ onClick }, ref) => (
  <i className="fas fa-ellipsis-h" ref={ref} onClick={onClick} />
));

interface Props {
  menus: HeaderMoreButton[];
}

export default function MobileHeaderMoreButton({ menus }: Props) {
  const items = menus.map((v) => (
    <Dropdown.Item key={v.name} onClick={v.onClick}>
      {v.icon && <i className={v.icon} />}
      <span className="ms-2">{v.name}</span>
    </Dropdown.Item>
  ));

  return (
    <Dropdown className="MobileHeaderMoreButton">
      <Dropdown.Toggle as={Toggle} />
      <Dropdown.Menu>{items}</Dropdown.Menu>
    </Dropdown>
  );
}
