import { generateQuery } from '../generate-api';

export interface DiaryStatsResult {
  cnt: number;
  length: DiaryStatsLength;
  date: DiaryStatsDate;
}

export interface DiaryStatsLength {
  total: number;
  average: number;
  maxList: DiaryStatsLengthMax[];
  cntList: DiaryStatsLengthCnt[];
}

export interface DiaryStatsLengthMax {
  length: number;
  date: string;
}

export interface DiaryStatsLengthCnt {
  range: number;
  cnt: number;
}

export interface DiaryStatsDate {
  min: string;
  max: string;
  period: DiaryStatsPeriod;
}

export interface DiaryStatsPeriod {
  years: number;
  months: number;
  days: number;
  totalDays: number;
}

const diaryStatsApi = generateQuery<{}, DiaryStatsResult>({
  api: () => ({
    url: `/api/v1/stats`,
    method: 'GET',
  }),
  key: 'diaryStatsApi',
});

export default diaryStatsApi;
