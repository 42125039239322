import { t } from 'i18next';

import PhotoDownloadItem from './PhotoDownloadItem';
import { Loading } from 'src/components/common/LoadingSuspense';
import { PageData } from 'src/model/api';
import { PhotoDownload } from 'src/model/photo';
import { useBreakpointMobile } from 'src/utils/breakpoint';

interface Props {
  data?: PageData<PhotoDownload>;
}

export default function PhotoDownloadList(props: Props) {
  const { data } = props;
  const isMobile = useBreakpointMobile();

  const header = (
    <div className="PhotoDownloadItem fw-bold">
      <div className="index">{t('PhotoDownloadList.no')}</div>
      <div className="content text-center">{t('PhotoDownloadList.content')}</div>
      <div className="status">{t('PhotoDownloadList.status')}</div>
      <div className="date">{t('PhotoDownloadList.date')}</div>
      <div className="download">{t('download')}</div>
    </div>
  );

  return (
    <div className="PhotoDownloadList">
      {isMobile || header}
      {renderRows(data)}
    </div>
  );
}

function renderRows(data?: PageData<PhotoDownload>) {
  if (!data) {
    return <Loading />;
  }

  const downloads = data?.data ?? [];
  const page = data?.page ?? 0;
  const pageSize = data?.pageSize ?? 0;

  return downloads.map((v, i) => {
    const index = i + page * pageSize;

    return <PhotoDownloadItem key={v.id} index={index} download={v} />;
  });
}
