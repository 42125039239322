import { generateQuery } from '../generate-api';
import { FriendPreview } from 'src/model/friend';

interface FriendListParams {
  userOnly?: boolean;
}

const friendListApi = generateQuery<FriendListParams, FriendPreview[]>({
  api: (params) => ({
    url: `/api/v1/friends`,
    method: 'GET',
    params,
    withCredentials: true,
  }),
  key: 'friendListApi',
  host: 'friend',
});

export default friendListApi;
