import { lang } from 'src/i18n';
import { DiaryPreview } from 'src/model/diary';
import DiaryRoutes from 'src/pages/diary/DiaryRoutes';
import DiaryMeetFriendView from 'src/pages/diary/detail/components/DiaryMeetFriendView';
import router from 'src/pages/router';

import './DiaryPreviewItem.scss';

interface Props {
  diary: DiaryPreview;
  query?: string;
  showAllContent?: boolean;
}

export default function DiaryPreviewItem({ diary, query, showAllContent }: Props) {
  const date = new Date(diary.date);
  const weekday = date.toLocaleString(lang, { weekday: 'short' });

  const onClick = () => {
    router.navigate(DiaryRoutes.detail({ date: diary.date, query }));
  };

  const { summary, content } = diary;

  const summaryElement = summary ? <div className="summary">{summary}</div> : undefined;

  const contentElement = query
    ? highlightQuery(content, showAllContent, query)
    : notHighlightQuery(content, showAllContent);

  return (
    <div className="DiaryPreviewItem hyunsub_border gray_bg_hover" onClick={onClick}>
      <div className="title_section">
        <div className="date">
          {diary.date} ({weekday})
        </div>
        {diary.friends.length > 0 && (
          <div className="friends">
            <DiaryMeetFriendView meetFriends={diary.friends} small />
          </div>
        )}
      </div>
      {summaryElement}
      <div className="content">{contentElement}</div>
    </div>
  );
}

function notHighlightQuery(content: string, showAllContent?: boolean): JSX.Element {
  const str = showAllContent ? content : processLastPart(content, 128);
  return <span>{str}</span>;
}

function highlightQuery(content: string, showAllContent: boolean | undefined, query: string): JSX.Element[] {
  const result: JSX.Element[] = [];

  const parts = content.split(query);
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];

    if (i !== 0) {
      result.push(
        <span key={`query_${i}`} className="query">
          {query}
        </span>,
      );
    }

    let content = '';
    if (showAllContent) {
      content = part;
    } else if (i === 0) {
      content = processFirstPart(part);
    } else if (i === parts.length - 1) {
      content = processLastPart(part);
    } else {
      content = processMiddlePart(part);
    }

    result.push(<span key={`content_${i}`}>{content}</span>);
  }

  return result;
}

const DEFAULT_SIZE = 64;

function processFirstPart(part: string): string {
  const size = DEFAULT_SIZE;
  if (part.length <= size) {
    return part;
  }

  const p = part.slice(-size, part.length);
  return '... ' + p;
}

function processLastPart(part: string, size: number = DEFAULT_SIZE): string {
  if (part.length <= size) {
    return part;
  }

  const p = part.slice(0, size);
  return p + ' ...';
}

function processMiddlePart(part: string): string {
  const size = DEFAULT_SIZE;
  if (part.length <= size * 2) {
    return part;
  }

  const p1 = part.slice(0, size);
  const p2 = part.slice(-size, part.length);
  return p1 + ' ... ' + p2;
}
