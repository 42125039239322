import { t } from 'i18next';
import { useContext, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import { AlbumDetailContext } from '../AlbumDetailContext';
import AlbumDetailHooks from '../AlbumDetailHooks';
import FriendMultiSelect from 'src/components/friend/FriendMultiSelect';
import { FriendPreview } from 'src/model/friend';
import { AlbumMember } from 'src/model/photo';

interface Props {
  members: AlbumMember[];
}

export default function AlbumFriendSelectModal(props: Props) {
  const { members } = props;
  const [{ showFriendModal: show }, setState] = useContext(AlbumDetailContext);

  const defaultValues: FriendPreview[] = members
    .filter((v) => !!v.friendId)
    .map((v) => ({
      id: v.friendId as string,
      name: v.name,
    }));

  const [friends, setFriends] = useState<FriendPreview[]>(defaultValues);
  const updateAlbumOwner = AlbumDetailHooks.useUpdateAlbumOwner();

  const onHide = () => {
    setState({ showFriendModal: false });
  };

  const onComplete = () => {
    onHide();
    updateAlbumOwner(friends);
  };

  return (
    <Modal className="FriendMultiSelectModal" show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('photo.term.album-share-setting')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>{t('friend.friend-list')}</Form.Label>
          <FriendMultiSelect value={friends} onChange={(v) => setFriends(v)} enabled={show} userOnly={true} />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onComplete}>{t('complete')}</Button>
      </Modal.Footer>
    </Modal>
  );
}
