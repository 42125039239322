import CommonRadioButton from 'src/components/common/form/CommonRadioButton';

const data = [0, 1, 2];

interface Props {
  onChange: (v: number) => void;
  value?: number;
}

export default function GeneticTypeRadioButton(props: Props) {
  return (
    <CommonRadioButton data={data} labelSelector={(v) => v.toString()} onChange={props.onChange} value={props.value} />
  );
}
