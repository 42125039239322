import { GeneticCombination } from 'src/model/genetic';

import './GeneticCombinationItem.scss';

interface Props {
  i: number;
  combination: GeneticCombination;
  hideGender?: boolean;
}

export default function GeneticCombinationItem(props: Props) {
  const { i, combination, hideGender } = props;

  return (
    <div className="GeneticCombinationItem hyunsub_border gray_bg_hover">
      <div className="index">{i + 1}</div>
      {hideGender || <div className="gender">{combination.isMale ? 'M' : 'F'}</div>}
      <div className="types">{combination.typeStr}</div>
      <div className="name">{combination.name}</div>
      <div className="typeHistory">{combination.typeHistory}</div>
      <div className="probability">{getProbability(combination.probability)}</div>
    </div>
  );
}

function getProbability(probability: number) {
  if (probability === 0) {
    return '0';
  }

  if (probability === 1) {
    return '1';
  }

  return `1 / ${probability}`;
}

export function GeneticCombinationHead(props: { hideGender?: boolean }) {
  return (
    <div className="GeneticCombinationItem fw-bold">
      <div className="index">#</div>
      {props.hideGender || <div className="gender">Gender</div>}
      <div className="types">Types</div>
      <div className="name">Name</div>
      <div className="typeHistory">Type History</div>
      <div className="probability">Probability</div>
    </div>
  );
}
